/* Max width (767px) */
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section-title {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .section-title .sub-title {
    margin-bottom: 7px;
    font-size: 13px;
  }
  .section-title h2 {
    font-size: 26px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .section-title p {
    margin-top: 5px;
  }
  .default-btn {
    font-size: 14.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 16px;
    padding-bottom: 12px;
  }
  .form-control {
    font-size: 15px;
  }
  .sidebar-modal .sidebar-modal-inner {
    width: 320px;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
  }
  .sidebar-modal .sidebar-modal-inner .close-btn {
    right: 25px;
    top: 15px;
  }
  .sidebar-modal .sidebar-about-area {
    margin-bottom: 20px;
  }
  .sidebar-modal .sidebar-about-area .title p {
    margin-top: 10px;
    font-size: 14px;
  }
  .sidebar-modal .sidebar-about-area .title h2 {
    font-size: 20px;
  }
  .sidebar-modal .sidebar-instagram-feed h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .main-banner {
    padding-top: 130px;
  }
  .main-banner-content {
    text-align: center;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .main-banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .main-banner-content h1 {
    font-size: 30px;
  }
  .main-banner-content p {
    font-size: 15px;
    margin-top: 10px;
  }
  .main-banner-content .btn-box {
    margin-top: 20px;
  }
  .banner-section {
    height: 100%;
    padding-top: 140px;
  }
  .banner-content {
    text-align: left;
    margin-top: 0;
  }
  .banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .banner-content h1 {
    font-size: 30px;
  }
  .banner-content p {
    font-size: 15px;
    margin-top: 10px;
  }
  .banner-content form {
    margin-left: auto;
    margin-right: auto;
  }
  .banner-content form .input-newsletter {
    height: 60px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
  }
  .banner-content form button {
    position: relative;
    right: 0;
    top: 0;
    height: 55px;
    font-size: 14px;
    margin-top: 15px;
    display: block;
    width: 100%;
  }
  .banner-image {
    margin-top: 60px;
  }
  .banner-image img:nth-child(1) {
    bottom: 80px;
    left: -9px;
    width: 100px;
    margin: 0 auto;
    right: 0;
  }
  .banner-image img:nth-child(2) {
    left: 0;
    bottom: 0;
  }
  .cloud-icon1 {
    top: 60%;
    right: 70%;
  }
  .cloud-icon2 {
    top: 70%;
    right: 0;
  }
  .cloud-icon3 {
    top: 80%;
    right: 75%;
  }
  .hero-banner {
    height: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .hero-banner-content {
    text-align: center;
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
  .hero-banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .hero-banner-content h1 {
    line-height: 1.3;
    font-size: 30px;
  }
  .hero-banner-content p {
    font-size: 15px;
    margin-top: 10px;
  }
  .hero-banner-content .btn-box {
    margin-top: 20px;
  }
  .hero-banner-content form .input-newsletter {
    height: 60px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
  }
  .hero-banner-content form button {
    position: relative;
    right: 0;
    top: 0;
    height: 55px;
    font-size: 14px;
    margin-top: 15px;
    display: block;
    width: 100%;
  }
  .hero-banner-image {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
  .hero-banner-image img:nth-child(2) {
    max-width: 100px;
  }
  .hero-banner-image img:nth-child(3) {
    max-width: 100px;
  }
  .hero-main-banner-content {
    text-align: center;
    margin-top: 0;
  }
  .hero-main-banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .hero-main-banner-content h1 {
    line-height: 1.3;
    font-size: 30px;
  }
  .hero-main-banner-content p {
    font-size: 15px;
    margin-top: 10px;
  }
  .hero-main-banner-content form .input-newsletter {
    height: 60px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
  }
  .hero-main-banner-content form button {
    position: relative;
    right: 0;
    top: 0;
    height: 55px;
    font-size: 14px;
    margin-top: 15px;
    display: block;
    width: 100%;
  }
  .hero-main-banner-image {
    margin-top: 40px;
    right: 0;
  }
  .hero-main-banner-image img {
    display: none;
    position: relative;
  }
  .hero-main-banner-image img:last-child {
    display: inline-block;
  }
  .shape-img1 img {
    max-width: 100px;
  }
  .shape-img2, .shape-img3, .shape-img4, .shape-img5, .shape-img6, .shape-img7, .shape-img8, .shape-img9, .shape-img10 {
    display: none;
  }
  .featured-services-area {
    padding-bottom: 30px;
  }
  .single-featured-box {
    padding: 96px 20px 35px 20px;
  }
  .single-featured-box .icon {
    margin-top: -7px;
    font-size: 35px;
  }
  .single-featured-box h3 {
    font-size: 20px;
  }
  .single-featured-box p {
    margin-top: 12px;
  }
  .single-featured-box .default-btn {
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 8px;
  }
  .about-content {
    padding-left: 0;
    text-align: center;
    margin-top: 35px;
  }
  .about-content .sub-title {
    margin-bottom: 7px;
    font-size: 13px;
  }
  .about-content h2 {
    font-size: 26px;
  }
  .about-content .features-list {
    text-align: start;
    margin-top: -5px;
  }
  .about-content .features-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .about-content .features-list li span {
    padding: 11px 12px 8px 37px;
    font-size: 14px;
  }
  .about-content .features-list li span i {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .about-inner-area {
    margin-top: 0;
  }
  .about-text {
    margin-top: 30px;
  }
  .about-text h3 {
    padding-bottom: 8px;
    font-size: 20px;
  }
  .overview-box {
    margin-bottom: 30px;
  }
  .overview-box .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
  .overview-box .overview-content .content {
    text-align: center;
    padding-right: 0;
    margin-top: 30px;
  }
  .overview-box .overview-content .content .sub-title {
    margin-bottom: 7px;
    font-size: 14px;
  }
  .overview-box .overview-content .content h2 {
    font-size: 24px;
  }
  .overview-box .overview-content .content .features-list {
    text-align: start;
    margin-top: -5px;
  }
  .overview-box .overview-content .content .features-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .overview-box .overview-content .content .features-list li span {
    padding: 11px 12px 8px 37px;
    font-size: 14px;
  }
  .overview-box .overview-content .content .features-list li span i {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .overview-box .overview-content .content.right-content {
    padding-left: 0;
  }
  .overview-box .overview-content .content .features-text h4 {
    margin-bottom: 10px;
    font-size: 17px;
  }
  .overview-box .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
  .services-area {
    padding-bottom: 30px;
  }
  .single-services-box {
    padding: 40px 25px;
  }
  .single-services-box .icon {
    transform: translateY(-15px);
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .single-services-box h3 {
    transform: translateY(-15px);
    font-size: 20px;
  }
  .single-services-box p {
    transform: translateY(-15px);
    margin-top: 11px;
  }
  .single-services-box .read-more-btn {
    font-size: 15px;
    opacity: 1;
    visibility: visible;
    bottom: 17px;
  }
  .services-details-overview {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;
  }
  .services-details-overview .services-details-desc {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    padding-right: 15px;
    padding-left: 15px;
  }
  .services-details-overview .services-details-desc h3 {
    margin-bottom: 12px;
    font-size: 20px;
  }
  .services-details-overview .services-details-desc .features-text {
    margin-top: 20px;
  }
  .services-details-overview .services-details-desc .features-text h4 {
    margin-bottom: 10px;
    font-size: 17px;
  }
  .services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
    font-size: 15px;
  }
  .services-details-overview .services-details-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    margin-top: 40px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .why-choose-content {
    padding-right: 0;
    text-align: center;
  }
  .why-choose-content .sub-title {
    margin-bottom: 7px;
    font-size: 13px;
  }
  .why-choose-content h2 {
    font-size: 26px;
  }
  .why-choose-content .features-text h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .why-choose-content .features-text h4 i {
    font-size: 14px;
  }
  .why-choose-image {
    margin-top: 25px;
  }
  .fun-facts-area {
    padding-bottom: 30px;
  }
  .fun-facts-area::before {
    display: none;
  }
  .single-fun-facts {
    padding: 33px 30px 20px;
    margin-bottom: 30px;
  }
  .single-fun-facts h3 {
    font-size: 40px;
  }
  .single-fun-facts p {
    margin-top: 5px;
    font-size: 14px;
  }
  .single-fun-facts .back-text {
    top: 5px;
    font-size: 120px;
  }
  .projects-area {
    padding-bottom: 30px;
  }
  .single-projects-box .projects-content {
    bottom: 10px;
    max-width: 280px;
  }
  .single-projects-box .projects-content h3 {
    font-size: 20px;
  }
  .single-projects-box .projects-content .category {
    margin-top: 6px;
    font-size: 15px;
  }
  .single-projects-box .plus-icon a {
    width: 65px;
    height: 65px;
    top: 15px;
    right: 15px;
  }
  .single-projects-box .plus-icon a span {
    width: 30px;
    height: 30px;
  }
  .projects-slides.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
  .projects-slides.owl-theme .owl-dots .owl-dot span {
    margin: 0 4px;
  }
  .projects-details-desc h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .projects-details-desc .features-text h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .projects-details-desc .project-details-info {
    margin-top: 15px;
  }
  .projects-details-desc .project-details-info .single-info-box {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  .projects-details-desc .project-details-info .single-info-box h4 {
    margin-bottom: 7px;
    font-size: 18px;
  }
  .features-list-tab .tabs {
    margin-top: -10px;
  }
  .features-list-tab .tabs li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 10px;
  }
  .features-list-tab .tabs li a {
    font-size: 15px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
  }
  .features-list-tab .tabs li a i {
    font-size: 25px;
  }
  .features-list-tab .features-overview .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
  .features-list-tab .features-overview .overview-content .content {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .features-list-tab .features-overview .overview-content .content .sub-title {
    margin-bottom: 7px;
    font-size: 14px;
  }
  .features-list-tab .features-overview .overview-content .content h2 {
    font-size: 22px;
  }
  .features-list-tab .features-overview .overview-content .content .features-list {
    text-align: start;
    margin-top: -5px;
  }
  .features-list-tab .features-overview .overview-content .content .features-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .features-list-tab .features-overview .overview-content .content .features-list li span {
    padding: 11px 12px 8px 37px;
    font-size: 14px;
  }
  .features-list-tab .features-overview .overview-content .content .features-list li span i {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .features-list-tab .features-overview .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    margin-top: 30px;
  }
  .features-list-tab .features-overview .overview-image .image {
    padding-left: 0;
    padding-right: 0;
  }
  .team-area {
    padding-bottom: 30px;
  }
  .single-team-box .image .social {
    top: auto;
    left: auto;
    transform: translate(0, 0);
    margin-top: 0;
    padding: 10px 15px 5px;
    right: 10px;
    bottom: 10px;
    opacity: 1;
    visibility: visible;
  }
  .single-team-box .image .social a {
    font-size: 15px;
    margin: 0 6px;
  }
  .single-team-box .content {
    padding: 25px 20px;
  }
  .single-team-box .content h3 {
    font-size: 20px;
  }
  .single-team-box .content span {
    margin-top: 7px;
  }
  .solution-area.extra-pb {
    padding-bottom: 85px;
  }
  .solution-content {
    text-align: center;
  }
  .solution-content .sub-title {
    margin-bottom: 7px;
    font-size: 13px;
  }
  .solution-content h2 {
    font-size: 28px;
  }
  .solution-video {
    margin-top: 60px;
  }
  .feedback-area {
    padding-bottom: 30px;
  }
  .feedback-area.bg-color {
    margin-top: 0;
  }
  .feedback-area.bg-color .section-title {
    margin-bottom: 10px;
  }
  .feedback-area.bg-color::before {
    margin-left: 0;
    margin-right: 0;
  }
  .feedback-area.bg-color::after {
    display: none;
  }
  .feedback-slides {
    padding-left: 0;
    padding-right: 0;
  }
  .single-feedback-item .client-info h3 {
    font-size: 18px;
  }
  .pricing-area {
    padding-bottom: 30px;
  }
  .single-pricing-box {
    padding: 40px 30px 15px 30px;
  }
  .single-pricing-box .pricing-header {
    margin: -40px -30px 23px -30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .single-pricing-box .pricing-header h3 {
    font-size: 20px;
  }
  .single-pricing-box .price {
    line-height: 35px;
    font-size: 35px;
  }
  .single-pricing-box .price sub {
    line-height: 14px;
    margin: 0 -5px 0 -8px;
    top: -2px;
    font-size: 14px;
  }
  .single-pricing-box .price-features-list {
    padding: 25px 20px;
    margin: 15px -30px 20px -30px;
  }
  .single-pricing-box .price-features-list li {
    font-size: 15px;
  }
  .single-pricing-box .price-features-list li i {
    font-size: 13px;
  }
  .single-pricing-box .price-features-list li i.flaticon-close {
    font-size: 11px;
  }
  .single-pricing-box .get-started-btn {
    font-size: 16px;
  }
  .partner-area {
    padding-bottom: 50px;
  }
  .single-partner-item {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .single-partner-item a {
    padding: 10px;
  }
  .blog-area {
    padding-bottom: 30px;
  }
  .blog-area .widget-area {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .single-blog-post .post-content {
    padding: 20px;
  }
  .single-blog-post .post-content .post-meta ul li {
    font-size: 14px;
  }
  .single-blog-post .post-content h3 {
    line-height: 1.4;
    font-size: 19px;
  }
  .single-blog-post .post-content .read-more-btn {
    bottom: 15px;
    left: 20px;
    font-size: 15px;
  }
  .single-blog-post:hover .post-content .read-more-btn {
    bottom: 16px;
  }
  .blog-details-desc .article-content {
    margin-top: 25px;
  }
  .blog-details-desc .article-content .entry-meta {
    margin-bottom: -10px;
  }
  .blog-details-desc .article-content .entry-meta ul li {
    display: block;
    margin-right: 0;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .blog-details-desc .article-content .entry-meta ul li::before {
    display: none;
  }
  .blog-details-desc .article-content h3 {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 14px;
    margin-top: 20px;
  }
  .blog-details-desc .article-footer {
    text-align: center;
  }
  .blog-details-desc .article-footer .article-tags {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-details-desc .article-footer .article-share {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  .blog-details-desc .article-footer .article-share .social {
    text-align: center;
  }
  blockquote, .blockquote {
    padding: 30px !important;
  }
  blockquote p, .blockquote p {
    font-size: 17px !important;
  }
  .comments-area {
    padding: 20px;
  }
  .comments-area .comment-author .avatar {
    left: 0;
    position: relative;
    display: block;
    margin-bottom: 10px;
  }
  .comments-area .comment-body {
    padding-left: 0;
  }
  .comments-area .comments-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .comments-area .comments-title::before {
    left: -20px;
  }
  .comments-area .comment-author {
    margin-bottom: 0.3em;
  }
  .comments-area .comment-metadata {
    font-size: 13px;
  }
  .comments-area .comment-respond .comment-reply-title {
    font-size: 20px;
  }
  .comments-area .comment-respond .comment-reply-title::before {
    left: -20px;
  }
  .comments-area .comment-respond label {
    font-size: 15px;
  }
  .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .comments-area .comment-respond .form-submit input {
    font-size: 15px;
    padding: 12px 25px 10px;
  }
  .subscribe-inner-area {
    padding: 60px 15px;
  }
  .subscribe-content .sub-title {
    margin-bottom: 8px;
    font-size: 13px;
  }
  .subscribe-content h2 {
    font-size: 28px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .subscribe-content .newsletter-form {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .subscribe-content .newsletter-form .input-newsletter {
    height: 60px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
  }
  .subscribe-content .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
    height: 55px;
    font-size: 14px;
    margin-top: 15px;
    display: block;
    width: 100%;
    background-color: var(--optionalColor);
    color: var(--whiteColor);
  }
  .subscribe-content .newsletter-form button:hover {
    background-color: var(--blackColor);
    color: var(--whiteColor);
  }
  .page-title-area {
    height: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-title-area.page-title-bg1 {
    background-position: center center;
  }
  .page-title-area.page-title-bg2 {
    background-position: center center;
  }
  .page-title-area .shape-img3, .page-title-area .shape-img7 {
    display: block;
  }
  .page-title-area .shape-img9 {
    left: 8%;
    top: 25%;
    display: block;
  }
  .page-title-content h2 {
    font-size: 25px;
  }
  .page-title-content ul {
    margin-top: 13px;
  }
  .page-title-content ul li {
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .page-title-content ul li::before {
    right: -16px;
    top: 3px;
    font-size: 10px;
  }
  .pagination-area {
    margin-top: 20px;
  }
  .pagination-area .page-numbers {
    width: 40px;
    height: 40px;
    margin: 0 2px;
    line-height: 45px;
    font-size: 17px;
  }
  .widget-area {
    margin-top: 40px;
  }
  .widget-area .widget .widget-title {
    font-size: 19px;
  }
  .widget-area .widget_karlson_posts_thumb .item .thumb {
    height: 70px;
    width: 70px;
    margin-right: 10px;
  }
  .widget-area .widget_karlson_posts_thumb .item .thumb .fullimage {
    width: 70px;
    height: 70px;
  }
  .widget-area .widget_karlson_posts_thumb .item .info time {
    margin-top: 0;
  }
  .widget-area .widget_karlson_posts_thumb .item .info .title {
    font-size: 15px;
  }
  .widget-area .widget_categories ul li {
    font-size: 14.5px;
  }
  .widget-area .widget_recent_comments ul li {
    font-size: 14.5px;
  }
  .widget-area .widget_recent_entries ul li {
    font-size: 14.5px;
  }
  .widget-area .widget_archive ul li {
    font-size: 14.5px;
  }
  .widget-area .widget_meta ul li {
    font-size: 14.5px;
  }
  .widget-area .tagcloud a {
    font-size: 13.5px !important;
  }
  .error-content h3 {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 35px;
  }
  .faq-accordion .accordion .accordion-title {
    font-size: 15px;
  }
  .faq-contact-image {
    text-align: center;
  }
  .faq-contact-form {
    padding-left: 0;
    margin-top: 35px;
  }
  .contact-area .section-title {
    margin-top: 30px;
  }
  .contact-info-box {
    margin-bottom: 30px;
  }
  .contact-info-box .icon {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 25px;
  }
  .contact-info-box h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .contact-image {
    text-align: center;
  }
  .contact-form {
    padding-left: 0;
    margin-top: 35px;
  }
  .footer-area {
    padding-top: 50px;
  }
  .single-footer-widget h3 {
    margin-bottom: 30px;
    font-size: 20px;
  }
  .single-footer-widget .footer-contact-info li {
    padding-left: 40px;
    font-size: 12.5px;
  }
  .single-footer-widget .footer-contact-info li i {
    font-size: 25px;
  }
  .single-footer-widget .footer-contact-info li a {
    font-size: 16px;
  }
  .single-footer-widget .footer-contact-info li .social {
    margin-top: 7px;
  }
  .single-footer-widget .footer-contact-info li .social li {
    margin-right: 10px;
  }
  .single-footer-widget .footer-contact-info li .social li a {
    font-size: 15px;
  }
  .single-footer-widget .footer-contact-info li .social li a i {
    font-size: 15px;
  }
  .single-footer-widget.pl-5, .single-footer-widget.px-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget .footer-quick-links li a {
    font-size: 15px;
  }
  .copyright-area {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .copyright-area ul {
    text-align: center;
    margin-top: 10px;
  }
  .copyright-area ul li::before {
    top: 2px;
  }
  .it-banner .d-table {
    display: inherit !important;
  }
  .it-banner .d-table .d-table-cell {
    display: inherit !important;
  }
  .it-banner, .seo-banner {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 60px;
  }
  .it-banner .container.mt-50, .seo-banner .container.mt-50 {
    margin-top: 0;
  }
  .it-banner .banner-btn, .seo-banner .banner-btn {
    margin-top: 20px;
  }
  .it-banner .it-banner-image, .it-banner .seo-banner-image, .seo-banner .it-banner-image, .seo-banner .seo-banner-image {
    margin-top: 30px;
  }
  .shape-img12 img {
    max-width: 150px;
  }
  .shape-img11 img {
    max-width: 40px;
  }
  .service-card-one.bg-white {
    padding: 25px 20px;
  }
  .service-card-one .icon i {
    margin-bottom: 20px;
  }
  .service-card-one h3 {
    font-size: 18px;
  }
  .it-overview .overview-content .content .features-list li span {
    padding: 11px 12px 8px 40px;
  }
  .it-overview .overview-content .content .features-list li span i {
    color: #0000ff;
    width: auto;
    height: auto;
    background-color: transparent;
    line-height: 1;
    font-size: 19px;
    left: 15px;
  }
  .work-card .content h3 {
    font-size: 20px;
  }
  .works-area .more-work {
    margin-top: 10px;
  }
  .solution-video .video-btn {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .solution-video .video-btn i::before {
    font-size: 25px;
  }
  .feedback-area-two .section-title {
    margin-bottom: 15px;
  }
  .single-pricing-table .pricing-header h3 {
    font-size: 20px;
  }
  .single-pricing-table .price {
    font-size: 35px;
  }
  .lets-work {
    text-align: center;
  }
  .lets-work h2 {
    font-size: 35px;
  }
  .lets-work .contact-btn {
    text-align: center;
    margin-top: 20px;
  }
  .seo-banner-slider.owl-theme .owl-nav button.owl-prev {
    top: auto;
    bottom: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transform: translateY(0%);
  }
  .seo-banner-slider.owl-theme .owl-nav button.owl-next {
    top: auto;
    bottom: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transform: translateY(0%);
  }
  .shape-img13, .shape-img14 {
    max-width: 100px;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(2) {
    margin-top: 0;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(3) {
    margin-top: 0;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(5) {
    margin-top: 0;
  }
  .about-area-two .section-title {
    text-align: center !important;
  }
  .about-area-two .about-content {
    padding-left: 0;
  }
  .service-card-one.gray-bg {
    padding: 25px 20px;
  }
  .choose-area-two .section-title h2 {
    font-size: 26px;
  }
  .choose-area-two .choose-image {
    margin-top: 30px;
  }
  .choose-area-two .choose-btn {
    text-align: center;
  }
  .analysis-img {
    margin-bottom: 30px;
  }
  .default-btn-two {
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 16px;
    padding-bottom: 12px;
  }
  .saas-banner-two {
    height: 100%;
    padding-top: 130px;
    padding-bottom: 80px;
  }
  .saas-banner-two .container.mt-50 {
    margin-top: 0;
  }
  .saas-banner-two .banner-btn {
    margin-top: 20px;
  }
  .saas-banner-two .saas-banner-image {
    margin-top: 30px;
  }
  .choose-area-two .choose-text h4 {
    font-size: 18px;
  }
  .fun-fact-card h3 {
    font-size: 28px;
  }
  .faq-area .faq-img.pr-3 {
    padding: 0;
    margin-bottom: 30px;
  }
  .navbar-area-two {
    background-color: #fff;
  }
  .machine-learning-banner {
    height: 100%;
    padding-top: 75px;
    padding-bottom: 130px;
  }
  .machine-learning-banner .ml-video {
    margin-top: 60px;
  }
  .machine-learning-banner .solution-video {
    position: inherit;
    transform: translateY(0%);
  }
  .machine-learning-banner a.default-btn.mr-4 {
    margin-right: 15px !important;
  }
  .machine-learning-slider.owl-theme .owl-nav .owl-prev {
    left: 15px;
    top: auto;
    transform: translateY(0%);
    width: 35px;
    height: 35px;
    bottom: 15px;
  }
  .machine-learning-slider.owl-theme .owl-nav .owl-next {
    right: 15px;
    top: auto;
    transform: translateY(0%);
    width: 35px;
    height: 35px;
    bottom: 15px;
  }
  .featured-solution-card {
    text-align: center;
  }
  .ml-service h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .portfolio-agency-banner {
    height: 100%;
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .portfolio-agency-banner a.default-btn.mr-4 {
    margin-right: 10px !important;
  }
  .form-content {
    margin: 50px auto;
    padding: 30px 20px;
  }
  .form-content .form-link {
    font-size: 13px;
  }
  .form-content .form-group.form-check label {
    font-size: 13px;
  }
  .topbar-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .topbar-social-links {
    text-align: center;
    margin-bottom: 5px;
  }
  .topbar-social-links li a i {
    font-size: 12px;
  }
  .topbar-action-list {
    text-align: center;
  }
  .topbar-action-list li {
    margin-right: 5px;
    font-size: 11.5px;
    padding-left: 15px;
  }
  .topbar-action-list li::before {
    display: none;
  }
  .navbar-area-with-position-relative.with-black-color .karlson-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .karlson-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .others-options .cart-items a {
    color: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .others-options .cart-items a:hover {
    color: var(--mainColor);
  }
  .value-trade-card p {
    font-size: 13.5px;
  }
  .value-trade-card p span i {
    top: -1px;
  }
  .cryptocurrency-banner-section {
    padding-top: 70px;
  }
  .cryptocurrency-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .cryptocurrency-banner-section::before {
    display: none;
  }
  .cryptocurrency-banner-content {
    top: 0;
  }
  .cryptocurrency-banner-content .sub-title {
    font-size: 13px;
  }
  .cryptocurrency-banner-content h1 {
    font-size: 30px;
  }
  .cryptocurrency-banner-content .banner-btn li {
    margin-right: 10px;
  }
  .cryptocurrency-banner-content .banner-btn li .video-btn {
    font-size: 14.5px;
  }
  .cryptocurrency-banner-image {
    text-align: center;
    margin-top: 30px;
  }
  .cryptocurrency-banner-image .cart1 {
    display: none;
  }
  .cryptocurrency-banner-image .cart2 {
    display: none;
  }
  .cryptocurrency-banner-image .graph {
    display: none;
  }
  .cryptocurrency-shape-1, .cryptocurrency-shape-2, .cryptocurrency-shape-3, .cryptocurrency-shape-4 {
    display: none;
  }
  .stats-fun-fact-box h3 {
    font-size: 20px;
  }
  .stats-fun-fact-box span {
    font-size: 14px;
  }
  .crypto-about-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .crypto-about-content {
    padding-left: 0;
  }
  .crypto-about-content .sub-title {
    font-size: 13px;
  }
  .crypto-about-content h3 {
    font-size: 25px;
  }
  .crypto-about-content .list li {
    font-size: 13.5px;
    padding-left: 22px;
  }
  .crypto-about-content .list li i {
    top: 1px;
  }
  .earning-platform-card {
    padding: 20px;
  }
  .earning-platform-card h3 {
    font-size: 20px;
  }
  .crypto-get-strated-area {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .crypto-get-strated-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .crypto-get-strated-content {
    padding-left: 0;
    top: 0;
  }
  .crypto-get-strated-content .content .sub-title {
    font-size: 13px;
  }
  .crypto-get-strated-content .content h3 {
    font-size: 25px;
  }
  .crypto-get-strated-content .crypto-get-strated-card h3 {
    font-size: 20px;
  }
  .key-features-card .key-content {
    padding: 20px;
  }
  .key-features-card .key-content .icon-image {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .key-features-card .key-content h3 {
    font-size: 20px;
  }
  .key-features-card .key-content.right-gap {
    right: 0;
  }
  .key-features-card .key-content.left-gap {
    left: 0;
  }
  .mining-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .mining-content {
    padding-left: 0;
  }
  .mining-content .sub-title {
    font-size: 13px;
  }
  .mining-content h3 {
    font-size: 25px;
  }
  .mining-content .mining-btn li {
    margin-right: 10px;
  }
  .mining-content .mining-btn li .video-btn {
    font-size: 14.5px;
  }
  .trade-over-inner-box {
    padding-left: 20px;
    padding-right: 20px;
  }
  .trade-over-card h3 {
    font-size: 25px;
  }
  .trade-over-card .over-shape {
    max-width: 60px;
  }
  .why-choose-us-content .sub-title {
    font-size: 13px;
  }
  .why-choose-us-content h3 {
    font-size: 25px;
  }
  .why-choose-us-content .choose-list li {
    font-size: 12.5px;
  }
  .why-choose-us-content .choose-list li::before {
    top: 17.5px;
  }
  .why-choose-us-card {
    padding: 20px;
    margin-top: 30px;
  }
  .why-choose-us-card h3 {
    font-size: 20px;
  }
  .choose-with-box-style .col-lg-6:nth-child(2) .why-choose-us-card {
    margin-bottom: 0;
  }
  .unique-single-feedback {
    padding: 20px;
  }
  .unique-single-feedback .client-info h3 {
    font-size: 18px;
  }
  .mobile-app-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .mobile-app-content {
    padding-left: 0;
  }
  .mobile-app-content h3 {
    font-size: 25px;
  }
  .mobile-app-content .app-btn-box .playstore-btn {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 10px;
    font-size: 13px;
  }
  .mobile-app-content .app-btn-box .playstore-btn img {
    left: 10px;
    max-width: 18px;
  }
  .mobile-app-content .app-btn-box .playstore-btn span {
    font-size: 14.5px;
  }
  .mobile-app-content .app-btn-box .applestore-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 10px;
    font-size: 13px;
  }
  .mobile-app-content .app-btn-box .applestore-btn img {
    left: 10px;
    max-width: 18px;
  }
  .mobile-app-content .app-btn-box .applestore-btn span {
    font-size: 14.5px;
  }
  .single-blog-card {
    padding: 20px;
  }
  .single-blog-card .tag {
    margin-top: 0;
  }
  .single-blog-card h3 {
    font-size: 20px;
  }
  .subscribe-wrap-inner-box {
    padding: 50px 20px;
  }
  .subscribe-wrap-inner-box .subscribe-content h2 {
    font-size: 25px;
  }
  .subscribe-wrap-inner-box .subscribe-content .newsletter-form {
    margin-top: 25px;
  }
  .subscribe-wrap-inner-box .subscribe-content .newsletter-form .input-newsletter {
    height: 55px;
  }
  .subscribe-wrap-inner-box .subscribe-content .newsletter-form button {
    position: relative;
    right: 0;
    top: 0;
  }
  .subscribe-shape-1, .subscribe-shape-2, .subscribe-shape-3 {
    display: none;
  }
  .single-footer-card.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-card h3 {
    font-size: 18px;
  }
  .single-footer-card .footer-quick-links li a {
    font-size: 13.5px;
    padding-left: 15px;
  }
  .single-footer-card .footer-quick-links li a i {
    font-size: 10.5px;
  }
  .footer-wrap-shape, .footer-wrap-shape-2 {
    display: none;
  }
  .copyright-wrap-area {
    text-align: center;
  }
  .copyright-wrap-area .list {
    text-align: center;
  }
  .cs-banner-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .cs-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .cs-banner-section::before {
    display: none;
  }
  .cs-banner-content .sub-title {
    font-size: 12px;
  }
  .cs-banner-content .sub-title::before {
    height: 2px;
  }
  .cs-banner-content h1 {
    font-size: 32px;
  }
  .cs-banner-content p {
    font-size: 15px;
  }
  .cs-banner-content .banner-btn li {
    margin-right: 5px;
  }
  .cs-banner-image {
    text-align: center;
    margin-top: 35px;
  }
  .cs-banner-shape, .cs-lock {
    display: none;
  }
  .cs-card-content h3 {
    font-size: 18px;
  }
  .cs-features-area .container-fluid {
    max-width: 540px;
  }
  .cs-features-item {
    padding: 50px 30px;
  }
  .cs-features-item .content h3 {
    font-size: 20px;
  }
  .cs-features-item .content .get-started-btn {
    font-size: 14px;
  }
  .cs-partner-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .cs-about-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .cs-about-content {
    padding-left: 0;
  }
  .cs-about-content .sub-title {
    font-size: 13px;
  }
  .cs-about-content h3 {
    font-size: 25px;
  }
  .cs-choose-content {
    padding-right: 0;
  }
  .cs-choose-content .sub-title {
    font-size: 13px;
  }
  .cs-choose-content h3 {
    font-size: 25px;
  }
  .cs-choose-content .list li {
    font-size: 13.5px;
  }
  .cs-choose-image {
    text-align: center;
    margin-top: 30px;
  }
  .cs-services-card {
    padding: 20px;
  }
  .cs-services-card h3 {
    font-size: 20px;
  }
  .cs-single-fun-facts {
    text-align: center;
  }
  .cs-single-fun-facts::before {
    left: 0;
    right: 0;
    margin: auto;
  }
  .cs-single-fun-facts h3 {
    font-size: 30px;
  }
  .cs-single-fun-facts h3 .sign-icon {
    font-size: 25px;
  }
  .cs-single-fun-facts p {
    font-size: 14.8px;
  }
  .leading-technology-image {
    text-align: center;
    margin-bottom: 35px;
  }
  .leading-technology-content {
    padding-left: 0;
  }
  .leading-technology-content .sub-title {
    font-size: 13px;
  }
  .leading-technology-content h3 {
    font-size: 25px;
  }
  .leading-technology-content .list li {
    font-size: 13.5px;
  }
  .cs-overview-item {
    padding: 20px;
  }
  .cs-overview-item .cs-overview-image {
    margin-bottom: 30px;
  }
  .cs-overview-item .cs-overview-content h3 {
    font-size: 18px;
  }
  .cs-overview-item .cs-overview-content .get-started-btn {
    font-size: 13.2px;
  }
  .bd-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .bd-banner-content .sub-title {
    font-size: 13.5px;
  }
  .bd-banner-content h1 {
    font-size: 30px;
  }
  .bd-banner-content .banner-btn li {
    margin-right: 5px;
  }
  .bd-banner-content .banner-btn li .video-btn {
    font-size: 14.5px;
  }
  .bd-banner-image {
    text-align: center;
    margin-top: 35px;
  }
  .bd-banner-image .circle-shape {
    display: none;
  }
  .bd-banner-shape-1, .bd-banner-shape-2, .bd-banner-shape-3 {
    display: none;
  }
  .bd-fun-facts-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 540px;
  }
  .bd-fun-facts-inner-box {
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .bd-single-fun-facts {
    padding-left: 82px;
  }
  .bd-single-fun-facts .image-icon {
    height: 65px;
    width: 65px;
    line-height: 65px;
  }
  .bd-single-fun-facts .image-icon img {
    max-width: 35px;
  }
  .bd-single-fun-facts h3 {
    font-size: 28px;
  }
  .bd-single-fun-facts h3 .sign-icon {
    font-size: 30px;
  }
  .bd-single-fun-facts p {
    font-size: 14.8px;
  }
  .bd-featured-card {
    padding: 20px;
  }
  .bd-featured-card h3 {
    font-size: 18px;
  }
  .bd-featured-shape {
    display: none;
  }
  .bd-about-image {
    text-align: center;
    margin-bottom: 35px;
  }
  .bd-about-content {
    padding-left: 0;
  }
  .bd-about-content .sub-title {
    font-size: 13px;
  }
  .bd-about-content h3 {
    font-size: 25px;
  }
  .bd-about-content .inner-content-card {
    padding: 20px;
  }
  .bd-about-content .inner-content-card .icon-image {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .bd-about-content .inner-content-card h4 {
    font-size: 18px;
  }
  .bd-services-card {
    padding: 20px;
  }
  .bd-services-card h3 {
    font-size: 18px;
  }
  .bd-services-card .get-started-btn {
    font-size: 13.5px;
  }
  .bd-choose-content {
    padding-right: 0;
  }
  .bd-choose-content .sub-title {
    font-size: 13px;
  }
  .bd-choose-content h3 {
    font-size: 25px;
  }
  .bd-choose-content .list li {
    font-size: 13.5px;
  }
  .bd-choose-image {
    text-align: center;
    margin-top: 30px;
  }
  .bd-overview-card {
    padding: 20px;
  }
  .bd-overview-card h3 {
    font-size: 18px;
  }
}
/* Min width (576px) to Max width (767px) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-banner .container-fluid {
    max-width: 540px;
  }
  .main-banner-image img:nth-child(2) {
    max-width: 170px;
  }
  .main-banner-image img:nth-child(3) {
    max-width: 170px;
  }
  .banner-content form .input-newsletter {
    height: 65px;
  }
  .banner-content form button {
    position: absolute;
    right: 5px;
    top: 5px;
    margin-top: 0;
    display: inline-block;
    width: auto;
  }
  .hero-banner .container-fluid {
    max-width: 540px;
  }
  .hero-banner-image img:nth-child(2) {
    max-width: 170px;
  }
  .hero-banner-image img:nth-child(3) {
    max-width: 170px;
  }
  .hero-main-banner-content form .input-newsletter {
    height: 65px;
  }
  .hero-main-banner-content form button {
    position: absolute;
    right: 5px;
    top: 5px;
    margin-top: 0;
    display: inline-block;
    width: auto;
  }
  .about-content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .overview-box .overview-content .content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .features-list-tab .tabs li {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .features-list-tab .features-overview .overview-content .content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .single-partner-item {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .subscribe-content .newsletter-form .input-newsletter {
    height: 65px;
  }
  .subscribe-content .newsletter-form button {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: var(--mainColor);
    color: var(--whiteColor);
    margin-top: 0;
    display: inline-block;
    width: auto;
  }
  .subscribe-content .newsletter-form button:hover {
    background-color: var(--optionalColor);
    color: var(--whiteColor);
  }
  .blog-details-desc .article-content {
    margin-top: 25px;
  }
  .blog-details-desc .article-content .entry-meta {
    margin-bottom: -10px;
  }
  .blog-details-desc .article-content .entry-meta ul li {
    display: inline-block;
    margin-right: 21px;
    font-size: 15px;
    margin-bottom: 0;
  }
  .blog-details-desc .article-content .entry-meta ul li::before {
    top: 9px;
    display: block;
  }
  .blog-details-desc .article-footer {
    text-align: start;
  }
  .blog-details-desc .article-footer .article-tags {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .blog-details-desc .article-footer .article-share {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
  }
  .blog-details-desc .article-footer .article-share .social {
    text-align: end;
  }
  .projects-details-desc .project-details-info .single-info-box {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .copyright-area {
    text-align: start;
  }
  .copyright-area ul {
    text-align: end;
    margin-top: 0;
  }
  .work-card .content {
    padding: 30px 15px;
  }
  .single-pricing-table .pricing-header {
    padding: 20px 20px;
  }
  .single-pricing-table .price {
    padding: 10px 20px 0;
    font-size: 30px;
  }
  .single-pricing-table .pricing-features {
    padding: 25px 20px;
  }
  .single-pricing-table .btn-box {
    padding: 0 20px;
  }
  .featured-solution-card h3 {
    font-size: 17px;
  }
  .earning-platform-card h3 {
    font-size: 18px;
  }
  .key-features-card {
    margin-bottom: 30px;
  }
  .key-features-card .key-content {
    padding: 30px 30px 30px 125px;
  }
  .key-features-card .key-content .icon-image {
    position: absolute;
    left: 25px;
    top: 30px;
  }
  .trade-over-card p {
    font-size: 14.5px;
  }
  .subscribe-wrap-inner-box .subscribe-content .newsletter-form button {
    margin-top: 20px;
  }
  .copyright-wrap-area {
    text-align: start;
  }
  .copyright-wrap-area .list {
    text-align: end;
  }
}
/* Min width (768px) to Max width (991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .default-btn {
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 11px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-title {
    margin-bottom: 50px;
  }
  .section-title .sub-title {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 35px;
  }
  .sidebar-modal .sidebar-modal-inner {
    width: 70%;
  }
  .main-banner {
    padding-top: 150px;
  }
  .main-banner .container-fluid {
    max-width: 720px;
  }
  .main-banner-content {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .main-banner-content .sub-title {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .main-banner-content h1 {
    font-size: 45px;
  }
  .main-banner-content p {
    font-size: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-section {
    height: 100%;
    padding-top: 150px;
  }
  .banner-content {
    margin-top: 0;
    text-align: left;
  }
  .banner-content .sub-title {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .banner-content h1 {
    font-size: 45px;
  }
  .banner-content p {
    font-size: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-content form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-image {
    margin-top: 90px;
  }
  .banner-image img:nth-child(1) {
    left: -5px;
    right: 0;
    margin: 0 auto;
    width: 140px;
    bottom: -15px;
  }
  .banner-image img:nth-child(2) {
    left: 0;
    top: 80px;
  }
  .hero-main-banner-content {
    margin-top: 0;
    text-align: center;
  }
  .hero-main-banner-content .sub-title {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .hero-main-banner-content h1 {
    font-size: 45px;
  }
  .hero-main-banner-content p {
    font-size: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-main-banner-content form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-main-banner-image {
    margin-top: 40px;
    right: 0;
  }
  .hero-main-banner-image img {
    display: none;
    position: relative;
  }
  .hero-main-banner-image img:last-child {
    display: inline-block;
  }
  .cloud-icon1 {
    top: 60%;
    right: 16.5%;
  }
  .cloud-icon2 {
    top: 80%;
    right: 2%;
  }
  .cloud-icon3 {
    top: 65%;
    right: 73%;
  }
  .hero-banner {
    height: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .hero-banner .container-fluid {
    max-width: 720px;
  }
  .hero-banner.banner-bg1 {
    background-position: bottom right;
  }
  .hero-banner-content {
    text-align: center;
    max-width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
  .hero-banner-content .sub-title {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .hero-banner-content h1 {
    font-size: 45px;
  }
  .hero-banner-content p {
    font-size: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-banner-image {
    margin-top: 35px;
  }
  .shape-img1 img {
    max-width: 100px;
  }
  .shape-img4, .shape-img9, .shape-img8 {
    display: none;
  }
  .featured-services-area {
    padding-bottom: 50px;
  }
  .single-featured-box {
    padding: 110px 20px 30px 20px;
  }
  .single-featured-box .icon {
    top: 25px;
    font-size: 45px;
    margin-top: 0;
  }
  .single-featured-box h3 {
    font-size: 22px;
  }
  .single-featured-box p {
    margin-top: 10px;
  }
  .single-featured-box .default-btn {
    font-size: 14px;
    padding-top: 13px;
    padding-bottom: 9px;
  }
  .about-content {
    margin-top: 35px;
    padding-left: 0;
    text-align: center;
  }
  .about-content .sub-title {
    font-size: 14px;
  }
  .about-content h2 {
    font-size: 35px;
  }
  .about-content .features-list {
    text-align: start;
  }
  .about-content .features-list li span {
    font-size: 15px;
  }
  .about-inner-area {
    margin-top: 5px;
  }
  .about-text {
    margin-top: 30px;
  }
  .about-text h3 {
    font-size: 22px;
  }
  .overview-box {
    margin-bottom: 35px;
  }
  .overview-box:first-child {
    margin-top: 0;
  }
  .overview-box .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    margin-top: 35px;
  }
  .overview-box .overview-content .content {
    text-align: center;
    padding-right: 0;
  }
  .overview-box .overview-content .content .sub-title {
    font-size: 14px;
  }
  .overview-box .overview-content .content h2 {
    font-size: 35px;
  }
  .overview-box .overview-content .content .features-text h4 {
    font-size: 17px;
  }
  .overview-box .overview-content .content .features-text p {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .overview-box .overview-content .content .features-list {
    text-align: start;
  }
  .overview-box .overview-content .content .features-list li span {
    font-size: 15px;
  }
  .overview-box .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
  .services-area {
    padding-bottom: 50px;
  }
  .single-services-box .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 32px;
  }
  .single-services-box h3 {
    font-size: 22px;
  }
  .services-details-overview {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 45px;
  }
  .services-details-overview .services-details-desc {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    padding-right: 15px;
    padding-left: 15px;
  }
  .services-details-overview .services-details-desc h3 {
    margin-bottom: 12px;
    font-size: 22px;
  }
  .services-details-overview .services-details-desc .features-text {
    margin-top: 20px;
  }
  .services-details-overview .services-details-desc .features-text h4 {
    margin-bottom: 10px;
    font-size: 17px;
  }
  .services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
    font-size: 15px;
  }
  .services-details-overview .services-details-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    margin-top: 45px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .why-choose-content {
    padding-right: 0;
    text-align: center;
    margin-bottom: 25px;
  }
  .why-choose-content .sub-title {
    font-size: 14px;
  }
  .why-choose-content h2 {
    font-size: 35px;
  }
  .why-choose-content .features-text h4 {
    font-size: 17px;
  }
  .why-choose-content .features-text p {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .fun-facts-area {
    padding-bottom: 50px;
  }
  .fun-facts-area::before {
    background-size: 500%;
  }
  .single-fun-facts {
    margin-bottom: 30px;
  }
  .single-fun-facts h3 {
    font-size: 45px;
  }
  .single-fun-facts p {
    font-size: 15px;
  }
  .projects-area {
    padding-bottom: 50px;
  }
  .single-projects-box .projects-content {
    max-width: 335px;
  }
  .single-projects-box .projects-content h3 {
    font-size: 22px;
  }
  .single-projects-box .plus-icon a {
    width: 75px;
    height: 75px;
    top: 20px;
    right: 20px;
  }
  .single-projects-box .plus-icon a span {
    width: 30px;
    height: 30px;
  }
  .projects-details-desc h3 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .projects-details-desc .project-details-info {
    margin-top: 15px;
  }
  .projects-details-desc .project-details-info .single-info-box {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-top: 15px;
  }
  .features-list-tab .tabs {
    margin-top: -10px;
  }
  .features-list-tab .tabs li {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
    padding-top: 10px;
  }
  .features-list-tab .tabs li a {
    font-size: 16px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .features-list-tab .tabs li a i {
    font-size: 30px;
  }
  .features-list-tab .tabs li a span {
    margin-top: 0;
  }
  .features-list-tab .features-overview .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
  .features-list-tab .features-overview .overview-content .content {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .features-list-tab .features-overview .overview-content .content .sub-title {
    font-size: 14px;
  }
  .features-list-tab .features-overview .overview-content .content h2 {
    font-size: 26px;
  }
  .features-list-tab .features-overview .overview-content .content .features-list {
    text-align: start;
  }
  .features-list-tab .features-overview .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    margin-top: 30px;
  }
  .team-area {
    padding-bottom: 50px;
  }
  .single-team-box .content h3 {
    font-size: 22px;
  }
  .single-team-box .content span {
    margin-top: 5px;
  }
  .solution-content {
    text-align: center;
  }
  .solution-content .sub-title {
    font-size: 14px;
  }
  .solution-content h2 {
    font-size: 40px;
  }
  .solution-content p {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
  .solution-video {
    margin-top: 80px;
  }
  .feedback-area {
    padding-bottom: 50px;
  }
  .feedback-area.bg-color::before {
    margin-left: 25px;
    margin-right: 25px;
  }
  .feedback-area.bg-color::after {
    background-size: 430%;
    margin-left: 25px;
    margin-right: 25px;
  }
  .feedback-slides {
    padding-left: 35px;
    padding-right: 35px;
  }
  .single-feedback-item p {
    font-size: 17px;
  }
  .single-feedback-item .client-info h3 {
    font-size: 18px;
  }
  .pricing-area {
    padding-bottom: 50px;
  }
  .single-pricing-box .pricing-header h3 {
    font-size: 22px;
  }
  .single-pricing-box .price {
    line-height: 45px;
    font-size: 45px;
  }
  .partner-area {
    padding-bottom: 70px;
  }
  .single-partner-item a {
    padding: 15px;
  }
  .blog-area {
    padding-bottom: 50px;
  }
  .blog-area .widget-area {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .single-blog-post .post-content h3 {
    font-size: 22px;
  }
  .blog-details-desc .article-content h3 {
    font-size: 22px;
  }
  .comments-area .comments-title {
    font-size: 22px;
  }
  .comments-area .comment-respond .comment-reply-title {
    font-size: 22px;
  }
  .subscribe-content .sub-title {
    font-size: 14px;
  }
  .subscribe-content h2 {
    font-size: 35px;
  }
  .page-title-area {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .page-title-area .page-title-bg2 {
    background-position: center center;
  }
  .page-title-area .page-title-bg1 {
    background-position: center center;
  }
  .page-title-content {
    margin-top: 65px;
  }
  .page-title-content h2 {
    padding-bottom: 8px;
    font-size: 35px;
  }
  .page-title-content ul {
    margin-top: 15px;
  }
  .widget-area {
    margin-top: 40px;
  }
  .widget-area .widget .widget-title {
    font-size: 20px;
  }
  .widget-area .widget_karlson_posts_thumb .item .info .title {
    font-size: 16px;
    max-width: 305px;
  }
  .widget-area .widget_categories ul li {
    font-size: 15px;
  }
  .widget-area .widget_recent_comments ul li {
    font-size: 15px;
  }
  .widget-area .widget_recent_entries ul li {
    font-size: 15px;
  }
  .widget-area .widget_archive ul li {
    font-size: 15px;
  }
  .widget-area .widget_meta ul li {
    font-size: 15px;
  }
  .error-content img {
    max-width: 75%;
  }
  .error-content h3 {
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .contact-area .section-title {
    margin-top: 50px;
  }
  .contact-info-box {
    margin-bottom: 30px;
  }
  .contact-info-box h3 {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .footer-area {
    padding-top: 70px;
  }
  .single-footer-widget.pl-5, .single-footer-widget.px-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 22px;
  }
  .single-footer-widget .footer-contact-info li {
    padding-left: 46px;
    font-size: 13.5px;
  }
  .single-footer-widget .footer-contact-info li i {
    font-size: 30px;
  }
  .single-footer-widget .footer-contact-info li a {
    font-size: 20px;
  }
  .single-footer-widget .footer-quick-links {
    margin-top: -12px;
  }
  .single-footer-widget .footer-quick-links li {
    padding-top: 12px;
  }
  .copyright-area {
    margin-top: 40px;
  }
  .copyright-area p {
    font-size: 15px;
  }
  .copyright-area ul li {
    font-size: 15px;
  }
  .pb-70 {
    padding-bottom: 50px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .it-banner, .seo-banner {
    padding-bottom: 80px;
    height: 100%;
    padding-top: 100px;
  }
  .it-banner .it-banner-image, .it-banner .seo-banner-image, .seo-banner .it-banner-image, .seo-banner .seo-banner-image {
    margin-top: 40px;
  }
  .lets-work {
    text-align: center;
  }
  .lets-work h2 {
    font-size: 45px;
  }
  .lets-work .contact-btn {
    text-align: center;
    margin-top: 25px;
  }
  .seo-featured-services .section-title {
    max-width: 100%;
  }
  .seo-featured-services .section-title h2 {
    max-width: 100%;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(2) {
    margin-top: 0;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(3) {
    margin-top: 0;
  }
  .seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(5) {
    margin-top: 0;
  }
  .about-area-two .section-title {
    max-width: 100%;
  }
  .about-area-two .about-content {
    padding-left: 0;
    text-align: left;
  }
  .choose-area-two .section-title {
    max-width: 100%;
  }
  .choose-area-two .section-title h2 {
    max-width: 100%;
  }
  .choose-area-two .choose-btn {
    text-align: center;
  }
  .choose-area-two .choose-image {
    margin-top: 30px;
  }
  .seo-analysis-section .section-title h2 {
    max-width: 100%;
  }
  .analysis-img {
    margin-bottom: 30px;
  }
  .saas-banner-two {
    padding-bottom: 80px;
    height: 100%;
    padding-top: 100px;
  }
  .saas-banner-two .saas-banner-image {
    margin-top: 40px;
  }
  .saas-banner-two .banner-content h1 {
    font-size: 40px;
  }
  .faq-area .faq-img.pr-3 {
    padding: 0;
    margin-bottom: 40px;
    text-align: center;
  }
  .navbar-area-two {
    background-color: #fff;
  }
  .machine-learning-banner .ml-video {
    margin-top: 80px;
  }
  .machine-learning-banner .solution-video {
    position: inherit;
    transform: translateY(0%);
  }
  .ml-service {
    padding: 30px 20px;
  }
  .ml-service h3 {
    font-size: 20px;
  }
  .topbar-wrap-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .topbar-social-links {
    margin-bottom: 5px;
  }
  .topbar-social-links li a i {
    font-size: 12px;
  }
  .topbar-action-list li {
    margin-right: 5px;
    font-size: 11.5px;
    padding-left: 15px;
  }
  .topbar-action-list li::before {
    display: none;
  }
  .navbar-area-with-position-relative.with-black-color .karlson-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .karlson-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .others-options .cart-items a {
    color: var(--whiteColor);
  }
  .navbar-area-with-position-relative.with-black-color .others-options .cart-items a:hover {
    color: var(--mainColor);
  }
  .value-trade-card p {
    font-size: 13.5px;
  }
  .value-trade-card p span {
    margin-left: 4px;
  }
  .value-trade-card p span i {
    top: -2px;
  }
  .cryptocurrency-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .cryptocurrency-banner-section::before {
    display: none;
  }
  .cryptocurrency-banner-content {
    top: 0;
  }
  .cryptocurrency-banner-content h1 {
    font-size: 55px;
  }
  .cryptocurrency-banner-image {
    text-align: center;
    margin-top: 50px;
  }
  .cryptocurrency-banner-image .cart1 {
    display: none;
  }
  .cryptocurrency-banner-image .cart2 {
    display: none;
  }
  .cryptocurrency-banner-image .graph {
    display: none;
  }
  .cryptocurrency-shape-1, .cryptocurrency-shape-2, .cryptocurrency-shape-3, .cryptocurrency-shape-4 {
    display: none;
  }
  .stats-fun-fact-box h3 {
    font-size: 22px;
  }
  .stats-fun-fact-box span {
    font-size: 15px;
  }
  .crypto-about-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .crypto-about-content {
    padding-left: 0;
  }
  .crypto-about-content h3 {
    font-size: 35px;
  }
  .earning-platform-card {
    padding: 20px;
  }
  .earning-platform-card h3 {
    font-size: 20px;
  }
  .crypto-get-strated-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .crypto-get-strated-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .crypto-get-strated-content {
    padding-left: 0;
    top: 0;
  }
  .crypto-get-strated-content .content h3 {
    font-size: 35px;
  }
  .crypto-get-strated-content .crypto-get-strated-card h3 {
    font-size: 22px;
  }
  .key-features-card .key-content {
    padding: 30px 30px 30px 110px;
  }
  .key-features-card .key-content .icon-image {
    height: 65px;
    width: 65px;
    line-height: 65px;
  }
  .key-features-card .key-content .icon-image img {
    max-width: 30px;
  }
  .key-features-card .key-content h3 {
    font-size: 22px;
  }
  .key-features-card .key-content.right-gap {
    right: 0;
  }
  .key-features-card .key-content.left-gap {
    left: 0;
  }
  .mining-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .mining-content {
    padding-left: 0;
  }
  .mining-content h3 {
    font-size: 35px;
  }
  .trade-over-inner-box {
    padding-left: 30px;
    padding-right: 30px;
  }
  .trade-over-card h3 {
    font-size: 25px;
  }
  .trade-over-card .over-shape {
    max-width: 60px;
  }
  .why-choose-us-content h3 {
    font-size: 35px;
  }
  .why-choose-us-card {
    padding: 20px;
    margin-top: 30px;
  }
  .why-choose-us-card h3 {
    font-size: 20px;
  }
  .choose-with-box-style .col-lg-6:nth-child(2) .why-choose-us-card {
    margin-bottom: 0;
  }
  .unique-single-feedback {
    padding: 20px;
  }
  .unique-single-feedback .client-info h3 {
    font-size: 18px;
  }
  .mobile-app-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .mobile-app-content {
    padding-left: 0;
  }
  .mobile-app-content h3 {
    font-size: 35px;
  }
  .single-blog-card {
    padding: 20px;
  }
  .single-blog-card .tag {
    margin-top: 0;
  }
  .single-blog-card h3 {
    font-size: 22px;
  }
  .subscribe-wrap-inner-box {
    padding: 50px 20px;
  }
  .subscribe-wrap-inner-box .subscribe-content h2 {
    font-size: 35px;
  }
  .subscribe-shape-1, .subscribe-shape-2, .subscribe-shape-3 {
    display: none;
  }
  .single-footer-card.ps-5 {
    padding-left: 0 !important;
  }
  .single-footer-card h3 {
    font-size: 18px;
  }
  .single-footer-card .footer-quick-links li a {
    font-size: 13.5px;
    padding-left: 15px;
  }
  .single-footer-card .footer-quick-links li a i {
    font-size: 10.5px;
  }
  .footer-wrap-shape, .footer-wrap-shape-2 {
    display: none;
  }
  .cs-banner-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .cs-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .cs-banner-section::before {
    display: none;
  }
  .cs-banner-content h1 {
    font-size: 55px;
  }
  .cs-banner-content p {
    font-size: 16.5px;
  }
  .cs-banner-image {
    text-align: center;
    margin-top: 35px;
  }
  .cs-banner-shape, .cs-lock {
    display: none;
  }
  .cs-features-area .container-fluid {
    max-width: 720px;
  }
  .cs-features-item {
    padding: 50px 30px;
  }
  .cs-features-item .content h3 {
    font-size: 20px;
  }
  .cs-features-item .content .get-started-btn {
    font-size: 14px;
  }
  .cs-partner-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .cs-about-image {
    text-align: center;
    margin-bottom: 30px;
  }
  .cs-about-content {
    padding-left: 0;
  }
  .cs-about-content h3 {
    font-size: 35px;
  }
  .cs-choose-content {
    padding-right: 0;
  }
  .cs-choose-content h3 {
    font-size: 35px;
  }
  .cs-choose-image {
    text-align: center;
    margin-top: 30px;
  }
  .cs-services-card {
    padding: 20px;
  }
  .cs-services-card h3 {
    font-size: 20px;
  }
  .cs-single-fun-facts {
    text-align: center;
  }
  .cs-single-fun-facts::before {
    left: 0;
    right: 0;
    margin: auto;
  }
  .cs-single-fun-facts h3 {
    font-size: 35px;
  }
  .cs-single-fun-facts h3 .sign-icon {
    font-size: 25px;
  }
  .cs-single-fun-facts p {
    font-size: 14.8px;
  }
  .leading-technology-image {
    text-align: center;
    margin-bottom: 35px;
  }
  .leading-technology-content {
    padding-left: 0;
  }
  .leading-technology-content h3 {
    font-size: 35px;
  }
  .cs-overview-item {
    padding: 20px;
  }
  .cs-overview-item .cs-overview-content h3 {
    font-size: 20px;
  }
  .bd-banner-section .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .bd-banner-content h1 {
    font-size: 55px;
  }
  .bd-banner-image {
    text-align: center;
    margin-top: 35px;
  }
  .bd-banner-image .circle-shape {
    display: none;
  }
  .bd-banner-shape-1, .bd-banner-shape-2, .bd-banner-shape-3 {
    display: none;
  }
  .bd-fun-facts-area .container-fluid {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    max-width: 720px;
  }
  .bd-fun-facts-inner-box {
    border-radius: 20px;
  }
  .bd-single-fun-facts {
    padding-left: 82px;
  }
  .bd-single-fun-facts .image-icon {
    height: 65px;
    width: 65px;
    line-height: 65px;
  }
  .bd-single-fun-facts .image-icon img {
    max-width: 35px;
  }
  .bd-single-fun-facts h3 {
    font-size: 28px;
  }
  .bd-single-fun-facts h3 .sign-icon {
    font-size: 30px;
  }
  .bd-single-fun-facts p {
    font-size: 14.8px;
  }
  .bd-featured-card {
    padding: 20px;
  }
  .bd-featured-card h3 {
    font-size: 22px;
  }
  .bd-featured-shape {
    display: none;
  }
  .bd-about-image {
    text-align: center;
    margin-bottom: 35px;
  }
  .bd-about-content {
    padding-left: 0;
  }
  .bd-about-content h3 {
    font-size: 35px;
  }
  .bd-about-content .inner-content-card h4 {
    font-size: 20px;
  }
  .bd-services-card h3 {
    font-size: 22px;
  }
  .bd-choose-content {
    padding-right: 0;
  }
  .bd-choose-content h3 {
    font-size: 35px;
  }
  .bd-choose-image {
    text-align: center;
    margin-top: 30px;
  }
  .bd-overview-card h3 {
    font-size: 22px;
  }
}
/* Min width (992px) to Max width (1199px) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-modal .sidebar-modal-inner {
    width: 50%;
  }
  .main-banner-content h1 {
    font-size: 50px;
  }
  .main-banner-content p {
    font-size: 16px;
  }
  .main-banner-content .btn-box {
    margin-top: 20px;
  }
  .banner-content {
    margin-top: -10px;
  }
  .banner-content h1 {
    font-size: 50px;
  }
  .banner-content p {
    font-size: 16px;
  }
  .banner-content .btn-box {
    margin-top: 20px;
  }
  .banner-image img:nth-child(2) {
    top: 180px;
  }
  .banner-image img:nth-child(1) {
    bottom: -85px;
    left: 60px;
    width: 190px;
  }
  .hero-banner-content {
    max-width: 450px;
  }
  .hero-banner-content h1 {
    font-size: 50px;
  }
  .hero-banner-content p {
    font-size: 16px;
  }
  .hero-banner-content .btn-box {
    margin-top: 20px;
  }
  .hero-banner-image {
    margin-left: 0;
    margin-right: 0;
  }
  .hero-banner-image img:nth-child(2) {
    max-width: 165px;
  }
  .hero-banner-image img:nth-child(3) {
    max-width: 200px;
  }
  .hero-main-banner-content h1 {
    font-size: 50px;
  }
  .hero-main-banner-content p {
    font-size: 16px;
  }
  .hero-main-banner-content .btn-box {
    margin-top: 20px;
  }
  .hero-main-banner-image {
    right: 0;
  }
  .hero-main-banner-image img {
    display: none;
    position: relative;
  }
  .hero-main-banner-image img:last-child {
    display: inline-block;
  }
  .projects-details-desc .project-details-info {
    margin-left: -8px;
    margin-right: -8px;
  }
  .projects-details-desc .project-details-info .single-info-box {
    padding-right: 8px;
    padding-left: 8px;
  }
  .error-content img {
    max-width: 80%;
  }
  .single-blog-post .post-content .post-meta ul li {
    font-size: 14px;
  }
  .single-blog-post .post-content h3 {
    font-size: 20px;
  }
  .features-list-tab .tabs li a {
    font-size: 14px;
  }
  .features-list-tab .features-overview .overview-content .content h2 {
    font-size: 25px;
  }
  .features-list-tab .features-overview .overview-content .content .features-list li span {
    font-size: 15px;
  }
  .service-card-one.bg-white {
    padding: 30px 25px;
  }
  .service-card-one h3 {
    font-size: 20px;
  }
  .overview-box .overview-content .content h2 {
    font-size: 32px;
  }
  .solution-content h2 {
    font-size: 45px;
  }
  .single-fun-facts p {
    font-size: 15px;
  }
  .single-pricing-table .pricing-header {
    padding: 20px 30px;
  }
  .single-pricing-table .price {
    padding: 10px 30px 0;
    font-size: 30px;
  }
  .single-pricing-table .pricing-features {
    padding: 30px 30px;
  }
  .single-pricing-table .btn-box {
    padding: 0 30px;
  }
  .lets-work h2 {
    font-size: 45px;
  }
  .seo-banner-slider.owl-theme .owl-nav button.owl-prev, .seo-banner-slider.owl-theme .owl-nav button.owl-next {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .about-area-two .section-title {
    max-width: 100%;
  }
  .seo-banner .banner-content h1 {
    font-size: 40px;
  }
  .seo-featured-services .section-title h2 {
    max-width: 560px;
    font-size: 35px;
  }
  .about-content h2 {
    font-size: 35px;
  }
  .fun-fact-card h3 {
    font-size: 30px;
  }
  .single-pricing-table .pricing-header .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .single-pricing-table .pricing-header .icon img {
    max-width: 50px;
  }
  .single-team-box .content h3 {
    font-size: 20px;
  }
  .machine-learning-banner .banner-content h1 {
    font-size: 40px;
  }
  .featured-solution-card {
    padding: 20px 18px;
  }
  .featured-solution-card h3 {
    font-size: 16px;
  }
  .featured-solution-card .read-more {
    font-size: 14px;
    margin-top: 10px;
  }
  .featured-solution-card .ml-service h3 {
    font-size: 20px;
  }
  .work-card .content h3 {
    font-size: 20px;
  }
  .cryptocurrency-banner-section::before {
    width: 335px;
  }
  .cryptocurrency-banner-content h1 {
    font-size: 50px;
  }
  .cryptocurrency-banner-image .cart1 {
    display: none;
  }
  .cryptocurrency-banner-image .cart2 {
    display: none;
  }
  .cryptocurrency-banner-image .graph {
    display: none;
  }
  .stats-fun-fact-box h3 {
    font-size: 25px;
  }
  .stats-fun-fact-box span {
    font-size: 15px;
  }
  .crypto-about-content h3 {
    font-size: 35px;
  }
  .crypto-get-strated-area {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .crypto-get-strated-content {
    padding-left: 0;
    top: 0;
  }
  .crypto-get-strated-content .content h3 {
    font-size: 35px;
  }
  .crypto-get-strated-content .crypto-get-strated-card h3 {
    font-size: 20px;
  }
  .key-features-card .key-content {
    padding: 20px 20px 20px 100px;
  }
  .key-features-card .key-content .icon-image {
    height: 65px;
    width: 65px;
    line-height: 65px;
    left: 20px;
  }
  .key-features-card .key-content .icon-image img {
    max-width: 30px;
  }
  .key-features-card .key-content h3 {
    font-size: 18px;
  }
  .key-features-card .key-content p {
    font-size: 14px;
  }
  .key-features-card .key-content.right-gap {
    right: 0;
  }
  .key-features-card .key-content.left-gap {
    left: 0;
  }
  .mining-content h3 {
    font-size: 35px;
  }
  .trade-over-card h3 {
    font-size: 25px;
  }
  .trade-over-card .over-shape {
    max-width: 50px;
  }
  .why-choose-us-content h3 {
    font-size: 35px;
  }
  .why-choose-us-card {
    padding: 15px;
  }
  .why-choose-us-card h3 {
    font-size: 20px;
  }
  .mobile-app-content {
    padding-left: 0;
  }
  .mobile-app-content h3 {
    font-size: 35px;
  }
  .single-blog-card {
    padding: 20px;
  }
  .single-blog-card h3 {
    font-size: 20px;
  }
  .cs-banner-content h1 {
    font-size: 55px;
  }
  .cs-banner-content p {
    font-size: 18px;
  }
  .cs-card-content h3 {
    font-size: 15px;
  }
  .cs-features-item {
    padding: 80px 65px;
  }
  .cs-features-item .content h3 {
    font-size: 20px;
  }
  .cs-about-area::before {
    width: 620px;
  }
  .cs-about-content {
    padding-left: 10px;
  }
  .cs-choose-content h3 {
    font-size: 35px;
  }
  .cs-services-card {
    padding: 20px;
  }
  .cs-services-card h3 {
    font-size: 19px;
  }
  .cs-single-fun-facts {
    text-align: center;
  }
  .cs-single-fun-facts::before {
    left: 0;
    right: 0;
    margin: auto;
  }
  .leading-technology-content {
    padding-left: 0;
  }
  .leading-technology-content h3 {
    font-size: 35px;
  }
  .leading-technology-content .list li {
    font-size: 13.5px;
  }
  .cs-overview-item .cs-overview-content h3 {
    font-size: 20px;
  }
  .bd-banner-section {
    padding-bottom: 80px;
  }
  .bd-banner-content h1 {
    font-size: 40px;
  }
  .bd-banner-image .circle-shape {
    width: 590px;
    height: 415px;
  }
  .bd-banner-shape-1 {
    max-width: 80px;
  }
  .bd-banner-shape-2 {
    top: -18%;
    left: 50%;
    transform: translateY(-50%) translateX(18%);
  }
  .bd-banner-shape-3 {
    max-width: 100px;
  }
  .bd-single-fun-facts {
    padding-left: 82px;
  }
  .bd-single-fun-facts .image-icon {
    height: 65px;
    width: 65px;
    line-height: 65px;
  }
  .bd-single-fun-facts .image-icon img {
    max-width: 35px;
  }
  .bd-single-fun-facts h3 {
    font-size: 28px;
  }
  .bd-single-fun-facts h3 .sign-icon {
    font-size: 30px;
  }
  .bd-single-fun-facts p {
    font-size: 12.8px;
  }
  .bd-featured-card {
    padding: 20px;
  }
  .bd-featured-card h3 {
    font-size: 20px;
  }
  .bd-about-content h3 {
    font-size: 35px;
  }
  .bd-about-content .inner-content-card h4 {
    font-size: 20px;
  }
  .bd-about-shape {
    top: -5%;
    right: 2%;
    transform: translateY(5%) translateX(-2%);
  }
  .bd-services-card {
    padding: 20px;
  }
  .bd-services-card h3 {
    font-size: 20px;
  }
  .bd-choose-content h3 {
    font-size: 35px;
  }
  .bd-choose-content .list li {
    font-size: 14.5px;
  }
}
/* Min width (1200px) to Max width (1399px) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cryptocurrency-banner-section::before {
    width: 405px;
  }
  .cryptocurrency-banner-content h1 {
    font-size: 55px;
  }
  .cryptocurrency-banner-image .cart1 {
    max-width: 190px;
  }
  .cryptocurrency-banner-image .cart2 {
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
  }
  .cryptocurrency-banner-image .graph {
    left: 0;
  }
  .earning-platform-card {
    padding: 20px;
  }
  .earning-platform-card h3 {
    font-size: 20px;
  }
  .key-features-card .key-content {
    padding: 20px 20px 20px 100px;
  }
  .key-features-card .key-content .icon-image {
    height: 65px;
    width: 65px;
    line-height: 65px;
    left: 20px;
  }
  .key-features-card .key-content .icon-image img {
    max-width: 30px;
  }
  .key-features-card .key-content h3 {
    font-size: 20px;
  }
  .key-features-card .key-content.right-gap {
    right: 0;
  }
  .key-features-card .key-content.left-gap {
    left: 0;
  }
  .cs-banner-content h1 {
    font-size: 65px;
  }
  .cs-card-content h3 {
    font-size: 18px;
  }
  .cs-fun-facts-area::before {
    width: 935px;
  }
  .bd-banner-image .circle-shape {
    right: -320px;
  }
  .bd-featured-card {
    padding: 20px;
  }
  .bd-featured-card h3 {
    font-size: 20px;
  }
  .bd-about-shape {
    top: -5%;
    right: 2%;
    transform: translateY(5%) translateX(-2%);
  }
}
/* Min width (1400px) to Max width (1450px) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-banner-image .circle-shape {
    right: -240px;
  }
}
/* Min width (1550px) */
@media only screen and (min-width: 1550px) {
  .main-banner {
    padding-top: 238px;
  }
  .navbar-area-with-position-relative .karlson-nav .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }
  .topbar-wrap-area .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }
  .cryptocurrency-banner-section .container-fluid {
    padding-left: 120px;
    padding-right: 0;
  }
  .cs-banner-section .container-fluid {
    padding-left: 180px;
    padding-right: 120px;
  }
  .cs-partner-area .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }
  .cs-about-area::before {
    width: 1050px;
  }
  .cs-fun-facts-area::before {
    width: 1370px;
  }
  .bd-banner-section .container-fluid {
    padding-left: 120px;
    padding-right: 0;
  }
  .bd-banner-image .circle-shape {
    width: 1050px;
    height: 690px;
  }
  .bd-fun-facts-inner-box {
    max-width: 1620px;
    padding-left: 120px;
    padding-right: 120px;
  }
}
/* Min width (1920px) */
@media only screen and (min-width: 1920px) {
  .cloud-shape img {
    width: 100%;
  }
}