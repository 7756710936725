/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
:root {
  --fontFamily: "Overpass", sans-serif;
  --mainColor: #008e43;
  --optionalColor: #ffd358;
  --whiteColor: #ffffff;
  --blackColor: #212529;
  --paragraphColor: #737b9a;
  --grayColor: #f9f9fc;
  --fontSize: 16px;
  --transition: 0.5s;
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
}

img {
  max-width: 100%;
  height: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: var(--blackColor);
}

button {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.gray-bg {
  background-color: var(--grayColor);
}

.bg-f4f7fe {
  background-color: #f4f7fe;
}

a {
  text-decoration: none;
  transition: var(--transition);
  outline: 0 !important;
  color: var(--blackColor);
}
a:hover {
  text-decoration: none;
  color: var(--mainColor);
}

p {
  font-size: var(--fontSize);
  line-height: 1.8;
  color: var(--paragraphColor);
  margin-bottom: 15px;
}
p:last-child {
  margin-bottom: 0;
}

.bg-F4F7FC {
  background-color: #f4f7fc;
}

.bg-fafafa {
  background-color: #fafafa;
}

/*default-btn*/
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: var(--whiteColor);
  text-transform: uppercase;
  background-color: var(--mainColor);
  transition: var(--transition);
  border-radius: 5px;
  font-weight: 600;
  font-size: 15.5px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 17px;
  padding-bottom: 13px;
}
.default-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--optionalColor);
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}
.default-btn:hover, .default-btn:focus {
  color: var(--whiteColor);
}
.default-btn:hover span, .default-btn:focus span {
  width: 225%;
  height: 562.5px;
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 55px;
}
.section-title .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.section-title h2 {
  max-width: 560px;
  font-weight: 700;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.section-title p {
  margin-top: 12px;
  margin-bottom: 0;
}

/*form-control*/
.form-control {
  height: 50px;
  border: 1px solid #eeeeee;
  background-color: transparent;
  border-radius: 0;
  transition: var(--transition);
  color: var(--blackColor);
  line-height: initial;
  padding-left: 13px;
  font-size: var(--fontSize);
}
.form-control:hover, .form-control:focus {
  box-shadow: unset;
}
.form-control::placeholder {
  color: #999999;
}
.form-control:focus {
  box-shadow: unset;
  border-color: var(--mainColor);
}

textarea.form-control {
  padding-top: 13px;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: transparent;
  top: 0;
  left: 0;
}
.preloader .loader {
  position: absolute;
  top: 43%;
  left: 0;
  right: 0;
  transform: translateY(-43%);
  text-align: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  transition: var(--transition);
}
.preloader .loader .box {
  width: 100%;
  height: 100%;
  background: var(--whiteColor);
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.preloader .loader .shadow {
  width: 100%;
  height: 5px;
  background: #000000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
.preloader::before, .preloader::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  z-index: -1;
  background: var(--mainColor);
  transition: 0.9s;
}
.preloader::after {
  left: auto;
  right: 0;
}
.preloader.preloader-deactivate {
  visibility: hidden;
}
.preloader.preloader-deactivate::after, .preloader.preloader-deactivate::before {
  width: 0;
}
.preloader.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}
/*================================================
Navbar Area CSS
=================================================*/
.karlson-nav {
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
}
.karlson-nav .navbar {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.karlson-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.karlson-nav .navbar .navbar-nav {
  margin-left: auto;
}
.karlson-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.karlson-nav .navbar .navbar-nav .nav-item a {
  font-size: var(--fontSize);
  font-weight: 500;
  color: #5b5b98;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.karlson-nav .navbar .navbar-nav .nav-item a:hover, .karlson-nav .navbar .navbar-nav .nav-item a:focus, .karlson-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item a i {
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-left: 1px;
}
.karlson-nav .navbar .navbar-nav .nav-item a::after {
  display: none;
}
.karlson-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.karlson-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.karlson-nav .navbar .navbar-nav .nav-item:hover a, .karlson-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: var(--whiteColor);
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 237px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  border-top: 3px solid var(--mainColor);
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu .new {
  background-color: red;
  color: var(--whiteColor);
  padding: 3px 4px 1px;
  position: relative;
  border-radius: 5px;
  margin-left: 5px;
  top: -2px;
  font-weight: 600;
  font-size: 14px;
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #696997;
  padding: 8px 0;
  margin: 0;
  font-size: var(--fontSize);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: -100%;
  visibility: hidden;
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #696997;
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}
.karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.karlson-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.karlson-nav .navbar .others-options {
  margin-left: 30px;
}
.karlson-nav .navbar .others-options .option-item {
  color: #5b5b98;
  display: inline-block;
  position: relative;
  line-height: 1;
}
.karlson-nav .navbar .others-options .option-item .search-btn {
  cursor: pointer;
  transition: var(--transition);
  color: #5b5b98;
  font-size: 20px;
  font-weight: 600;
}
.karlson-nav .navbar .others-options .option-item .search-btn:hover {
  color: var(--mainColor);
}
.karlson-nav .navbar .others-options .option-item .close-btn {
  cursor: pointer;
  display: none;
  transition: var(--transition);
  width: 20px;
  color: #5b5b98;
  font-size: 18px;
}
.karlson-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}
.karlson-nav .navbar .others-options .option-item .close-btn:hover {
  color: var(--mainColor);
}
.karlson-nav .navbar .others-options .burger-menu {
  width: 35px;
  height: auto;
  background-color: transparent;
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
}
.karlson-nav .navbar .others-options .burger-menu span {
  display: block;
  width: 35px;
  height: 3px;
  background: #5b5b98;
  transition: var(--transition);
  border-radius: 30px;
  margin-left: auto;
  margin-bottom: 4px;
}
.karlson-nav .navbar .others-options .burger-menu span:nth-child(3) {
  margin-bottom: 0;
  width: 28px;
  margin-left: auto;
}
.karlson-nav .navbar .others-options .burger-menu:hover span {
  background: var(--blackColor);
}
.karlson-nav .navbar .others-options .burger-menu:hover span:nth-child(3) {
  width: 35px;
}

.navbar-area {
  background-color: transparent;
  transition: var(--transition);
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background: var(--whiteColor) !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: var(--transition);
}
.navbar-area.navbar-style-two {
  position: relative;
  background-color: var(--whiteColor);
}
.navbar-area.navbar-style-two.is-sticky {
  position: fixed;
}

.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: var(--whiteColor);
  z-index: 2;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: var(--blackColor);
  outline: 0;
  transition: var(--transition);
  padding-top: 4px;
  padding-left: 10px;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: var(--mainColor);
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: var(--paragraphColor);
  transition: var(--transition);
  padding: 0;
}
.search-overlay.search-popup .search-form .search-button:hover, .search-overlay.search-popup .search-form .search-button:focus {
  color: var(--mainColor);
}

.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  overflow: hidden;
}
.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 30%;
  overflow-y: scroll;
  height: 100%;
  background-color: var(--whiteColor);
  transition: 0.7s;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 20px;
  transition: var(--transition);
  color: var(--blackColor);
  opacity: 0.66;
  cursor: pointer;
}
.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}
.sidebar-modal .sidebar-about-area {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-about-area .title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}
.sidebar-modal .sidebar-about-area .title p {
  margin-top: 15px;
  font-size: 15px;
}
.sidebar-modal .sidebar-instagram-feed {
  margin-bottom: 40px;
}
.sidebar-modal .sidebar-instagram-feed h2 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
}
.sidebar-modal .sidebar-instagram-feed ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  margin-top: -10px;
}
.sidebar-modal .sidebar-instagram-feed ul li {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.sidebar-modal .sidebar-instagram-feed ul li a {
  display: block;
  position: relative;
}
.sidebar-modal .sidebar-instagram-feed ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.sidebar-modal .sidebar-instagram-feed ul li a:hover::before {
  opacity: 0.5;
  visibility: visible;
}
.sidebar-modal .sidebar-contact-area .contact-info {
  text-align: center;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: var(--mainColor);
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
  color: var(--blackColor);
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: var(--blackColor);
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: var(--mainColor);
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: var(--paragraphColor);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 3px;
  padding-left: 0;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
  display: block;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
  font-size: 14px;
}
.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a:hover {
  color: var(--whiteColor);
  border-color: var(--mainColor);
  background-color: var(--mainColor);
}
.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

.navbar-light .navbar-toggler {
  border-color: var(--blackColor);
  color: var(--blackColor);
  border-radius: 0;
}
.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    border-bottom: 1px solid #eeeeee;
  }
  .navbar-area.is-sticky {
    border-bottom: none;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .karlson-nav #navbarSupportedContent {
    border: 1px solid #eeeeee;
    background-color: var(--whiteColor);
    padding: 10px 22px;
    margin-top: 15px;
  }
  .karlson-nav .navbar .navbar-nav .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .karlson-nav .navbar .navbar-nav .nav-item a {
    margin-left: 0;
    margin-right: 0;
  }
  .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    opacity: 1;
    width: 100%;
    display: none;
    margin-top: 10px;
    top: 0 !important;
    position: relative;
    visibility: visible;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
  .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu.show {
    display: block;
  }
  .karlson-nav .navbar .others-options {
    padding-bottom: 10px;
    margin-left: 0;
    margin-top: 10px;
  }
  .navbar-light .navbar-toggler {
    box-shadow: unset;
  }
  .search-overlay.search-popup {
    width: 270px;
    right: auto;
    left: 0;
  }
}
/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: #f5f5fc;
  padding-top: 190px;
}

.main-banner-content {
  text-align: center;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}
.main-banner-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}
.main-banner-content h1 {
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 60px;
}
.main-banner-content p {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 0;
}
.main-banner-content .btn-box {
  margin-top: 25px;
}
.main-banner-content form {
  position: relative;
  margin-top: 30px;
}
.main-banner-content form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 70px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}
.main-banner-content form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.main-banner-content form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.main-banner-content form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}

.main-banner-image {
  text-align: center;
}

.banner-section {
  overflow: hidden;
  position: relative;
  background-color: #f5f5fc;
  z-index: 1;
  height: 950px;
}

.banner-content {
  margin-top: -20px;
}
.banner-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}
.banner-content h1 {
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 59px;
}
.banner-content p {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 0;
}
.banner-content form {
  max-width: 580px;
  position: relative;
  margin-top: 30px;
}
.banner-content form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 5px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}
.banner-content form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.banner-content form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 5px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.banner-content form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}

.banner-image {
  position: relative;
  text-align: center;
}
.banner-image img:nth-child(1) {
  position: absolute;
  z-index: 1;
  bottom: -180px;
  left: 65px;
  right: 0;
  margin: 0 auto;
}
.banner-image img:nth-child(2) {
  position: relative;
  left: 35px;
  top: 150px;
  z-index: -1;
}

.cloud-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: auto;
}

.cloud-icon {
  position: absolute;
  right: 15%;
  top: 20%;
  transform: translateY(-20%) translateX(-15%);
  z-index: -1;
}

.cloud-icon1 {
  position: absolute;
  z-index: -1;
  top: 12%;
  right: 36.5%;
  animation: moveLeftBounce 3s linear infinite;
}

.cloud-icon2 {
  position: absolute;
  z-index: -1;
  top: 36%;
  right: 20%;
  animation: moveLeftBounce 5s linear infinite;
}

.cloud-icon3 {
  position: absolute;
  z-index: -1;
  top: 48%;
  right: 40%;
  animation: moveLeftBounce 3s linear infinite;
}

.hero-banner {
  overflow: hidden;
  height: 900px;
  position: relative;
  z-index: 1;
}
.hero-banner.banner-bg1 {
  background: transparent url(assets/img/banner-bg1.jpg) right top no-repeat;
}
.hero-banner.banner-bg2 {
  background: transparent url(assets/img/banner-bg2.jpg) left top no-repeat;
}

.hero-banner-content {
  max-width: 635px;
  margin-top: 85px;
  margin-left: auto;
}
.hero-banner-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}
.hero-banner-content h1 {
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 59px;
}
.hero-banner-content p {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 0;
}
.hero-banner-content .btn-box {
  margin-top: 25px;
}
.hero-banner-content form {
  position: relative;
  margin-top: 30px;
}
.hero-banner-content form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 70px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}
.hero-banner-content form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.hero-banner-content form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.hero-banner-content form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}

.hero-banner-image {
  position: relative;
  text-align: center;
  margin-left: 30px;
  margin-right: 50px;
  margin-top: 85px;
}
.hero-banner-image img:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 0;
}
.hero-banner-image img:nth-child(3) {
  position: absolute;
  right: 0;
  bottom: 0;
}

.hero-main-banner-content {
  margin-top: 85px;
}
.hero-main-banner-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
}
.hero-main-banner-content h1 {
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 59px;
}
.hero-main-banner-content p {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 0;
}
.hero-main-banner-content .btn-box {
  margin-top: 25px;
}
.hero-main-banner-content form {
  position: relative;
  margin-top: 30px;
}
.hero-main-banner-content form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 70px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}
.hero-main-banner-content form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.hero-main-banner-content form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.hero-main-banner-content form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}

.hero-main-banner-image {
  position: relative;
  right: -20px;
  margin-top: 85px;
}
.hero-main-banner-image img {
  position: absolute;
}
.hero-main-banner-image img:nth-child(1) {
  z-index: 3;
  top: -130px;
  left: -18px;
}
.hero-main-banner-image img:nth-child(2) {
  right: 145px;
  top: -287px;
}
.hero-main-banner-image img:nth-child(3) {
  left: 25px;
  top: -20px;
}
.hero-main-banner-image img:nth-child(4) {
  right: 12%;
  top: 118px;
  z-index: 1;
}
.hero-main-banner-image img:nth-child(5) {
  left: 28%;
  top: -120px;
  z-index: 2;
}
.hero-main-banner-image img:nth-child(6) {
  right: 35%;
  z-index: 1;
  top: -150px;
}
.hero-main-banner-image img:nth-child(7) {
  right: 20px;
  top: -110px;
}
.hero-main-banner-image img:nth-child(8) {
  right: 25px;
  top: -140px;
}
.hero-main-banner-image img:nth-child(9) {
  right: 30%;
  z-index: 1;
  top: -35px;
}
.hero-main-banner-image img:nth-child(10) {
  left: 37%;
  top: -28px;
  z-index: 1;
}
.hero-main-banner-image img:nth-child(11) {
  left: 37%;
  top: -160px;
  z-index: 1;
}
.hero-main-banner-image img:nth-child(12) {
  left: 90px;
  top: -110px;
}
.hero-main-banner-image img:nth-child(13) {
  left: 56%;
  top: -25px;
  z-index: 2;
}
.hero-main-banner-image img:nth-child(14) {
  right: 105px;
  top: -28px;
}
.hero-main-banner-image img:last-child {
  display: none;
}

.shape-img1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.shape-img2 {
  position: absolute;
  left: 5%;
  top: 10%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img3 {
  position: absolute;
  right: 5%;
  bottom: 5%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img4 {
  position: absolute;
  right: 8%;
  top: 8%;
  z-index: -1;
  animation: animationFramesTwo 20s infinite linear;
}

.shape-img5 {
  position: absolute;
  left: 2%;
  bottom: 25%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}

.shape-img6 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  z-index: -1;
  bottom: 0;
  animation: rotate3d 4s linear infinite;
}
.shape-img6 img {
  opacity: 0.2;
}

.shape-img7 {
  position: absolute;
  left: 10%;
  top: 35%;
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}
.shape-img7 img {
  opacity: 0.3;
}

.shape-img8 {
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}
.shape-img8 img {
  opacity: 0.4;
}

.shape-img9 {
  position: absolute;
  left: 20%;
  top: 15%;
  z-index: -1;
  animation: moveScale 3s linear infinite;
}
.shape-img9 img {
  opacity: 0.4;
}

.shape-img10 {
  position: absolute;
  left: 75%;
  z-index: -1;
  top: 40%;
  animation: animationFramesOne 20s infinite linear;
}

@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes moveScale {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
/*================================================
Featured Services Area CSS
=================================================*/
.featured-services-area {
  padding-bottom: 70px;
}
.featured-services-area.ptb-100.pt-0 {
  padding-top: 40px !important;
}

.single-featured-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: #f9f9fc;
  position: relative;
  padding: 115px 25px 35px 25px;
  overflow: hidden;
  transition: var(--transition);
}
.single-featured-box .icon {
  color: var(--optionalColor);
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 55px;
  margin-top: -12px;
}
.single-featured-box .icon.color-fb7756 {
  color: #fb7756;
}
.single-featured-box .icon.color-facd60 {
  color: #facd60;
}
.single-featured-box .icon.color-1ac0c6 {
  color: #1ac0c6;
}
.single-featured-box h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}
.single-featured-box p {
  margin-top: 13px;
  margin-bottom: 0;
}
.single-featured-box .default-btn {
  margin-top: 20px;
  background-color: transparent;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
  font-size: 15px;
  padding-top: 14px;
  padding-bottom: 10px;
}
.single-featured-box .default-btn:hover {
  box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
}
.single-featured-box .default-btn span {
  background: var(--mainColor);
}
.single-featured-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: var(--whiteColor);
}
.single-featured-box:hover .icon {
  animation: iconTop 0.4s ease-in-out;
}
.single-featured-box:hover .default-btn {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

@keyframes iconTop {
  0% {
    transform: translate(-50%, 0);
  }
  25% {
    opacity: 0;
    transform: translate(-50%, -70%);
  }
  50% {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.about-image {
  text-align: center;
}

.about-content {
  padding-left: 30px;
}
.about-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.about-content h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 40px;
}
.about-content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.about-content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.about-content .features-list li span {
  display: block;
  background-color: #f8faff;
  border-radius: 5px;
  padding: 13px 12px 8px 40px;
  z-index: 1;
  position: relative;
  transition: var(--transition);
  font-weight: 600;
  font-size: 15.5px;
}
.about-content .features-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background-color: var(--mainColor);
  z-index: -1;
  transition: var(--transition);
}
.about-content .features-list li span i {
  font-size: 12px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  width: 23px;
  height: 23px;
  line-height: 23px;
  transition: var(--transition);
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.about-content .features-list li span:hover {
  color: var(--whiteColor);
}
.about-content .features-list li span:hover::before {
  width: 100%;
}
.about-content .features-list li span:hover i {
  background-color: var(--optionalColor);
}

.about-inner-area {
  margin-top: 100px;
}

.home-text-ul h3 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}
.home-text-ul .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}
.home-text-ul .features-list li {
  position: relative;
  margin-bottom: 12px;
  color: var(--blackColor);
  padding-top: 3px;
  padding-left: 30px;
}
.home-text-ul .features-list li i {
  font-size: 8px;
  color: var(--blackColor);
  background-color: #eaeaf2;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  transition: var(--transition);
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home-text-ul .features-list li:last-child {
  margin-bottom: 0;
}
.home-text-ul .features-list li:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.about-text h3 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}
.about-text h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 50px;
  background-color: var(--mainColor);
  height: 1px;
}
.about-text .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}
.about-text .features-list li {
  position: relative;
  margin-bottom: 12px;
  color: var(--blackColor);
  padding-top: 3px;
  padding-left: 30px;
}
.about-text .features-list li i {
  font-size: 8px;
  color: var(--blackColor);
  background-color: #eaeaf2;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  transition: var(--transition);
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.about-text .features-list li:last-child {
  margin-bottom: 0;
}
.about-text .features-list li:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
}

.single-services-box {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  padding: 40px 30px;
  position: relative;
  transition: var(--transition);
  z-index: 1;
}
.single-services-box .icon {
  width: 75px;
  height: 75px;
  text-align: center;
  line-height: 75px;
  background-color: rgba(250, 112, 112, 0.1);
  border-radius: 50%;
  color: var(--optionalColor);
  transition: var(--transition);
  font-size: 35px;
  margin-bottom: 25px;
}
.single-services-box .icon.bg-f78acb {
  background-color: rgba(247, 138, 203, 0.2);
  color: #f78acb;
}
.single-services-box .icon.bg-cdf1d8 {
  background: rgba(68, 206, 111, 0.2);
  color: #44ce6f;
}
.single-services-box .icon.bg-c679e3 {
  color: #c679e3;
  background: rgba(198, 121, 227, 0.2);
}
.single-services-box .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.2);
}
.single-services-box .icon.bg-00aeff {
  background: rgba(0, 174, 255, 0.2);
  color: #00aeff;
}
.single-services-box h3 {
  transition: 0.4s;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}
.single-services-box h3 a {
  display: inline-block;
}
.single-services-box p {
  transition: 0.4s;
  margin-top: 13px;
  margin-bottom: 0;
}
.single-services-box .read-more-btn {
  color: var(--mainColor);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: 0.6s;
  font-size: var(--fontSize);
  font-weight: 400;
}
.single-services-box .read-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 0;
  transition: var(--transition);
  height: 1px;
  background: var(--mainColor);
}
.single-services-box .read-more-btn i {
  font-size: 14px;
}
.single-services-box .read-more-btn:hover::before {
  width: 100%;
}
.single-services-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: -1;
  background-color: var(--mainColor);
  transition: var(--transition);
}
.single-services-box:hover {
  transform: translateY(-10px);
}
.single-services-box:hover::before {
  width: 100%;
}
.single-services-box:hover h3 {
  color: var(--whiteColor);
  transform: translateY(-15px);
}
.single-services-box:hover h3 a {
  color: var(--whiteColor);
}
.single-services-box:hover p {
  transform: translateY(-15px);
  color: var(--whiteColor);
}
.single-services-box:hover .icon {
  transform: translateY(-15px) rotate(360deg);
  background-color: var(--whiteColor);
  color: var(--mainColor);
}
.single-services-box:hover .read-more-btn {
  opacity: 1;
  visibility: visible;
  color: var(--whiteColor);
  bottom: 15px;
}
.single-services-box:hover .read-more-btn::before {
  background-color: var(--whiteColor);
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
  align-items: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-bottom: 60px;
}
.services-details-overview:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}
.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.services-details-overview .services-details-desc .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: var(--mainColor);
}
.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  border: none;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: var(--whiteColor);
  margin-bottom: 10px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:nth-child(2) .accordion-title i, .services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:nth-child(4) .accordion-title i, .services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:nth-child(6) .accordion-title i, .services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:nth-child(8) .accordion-title i {
  background: var(--optionalColor);
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: var(--blackColor);
  position: relative;
  border: none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: block;
  font-size: var(--fontSize);
  font-weight: 700;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: var(--mainColor);
  color: var(--whiteColor);
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}
.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}
.services-details-overview .services-details-image {
  -ms-flex: 0 0 50%;
  max-width: 50%;
  flex: 0 0 50%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-content {
  padding-right: 30px;
}
.why-choose-content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.why-choose-content h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 35px;
}
.why-choose-content .features-text {
  margin-top: 25px;
}
.why-choose-content .features-text h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.why-choose-content .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: var(--mainColor);
}

.why-choose-image {
  text-align: center;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-box {
  align-items: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-bottom: 100px;
}
.overview-box:last-child {
  margin-bottom: 0;
}
.overview-box .overview-content {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: calc(var(--bs-gutter-x) / 2);
  padding-right: calc(var(--bs-gutter-x) / 2);
}
.overview-box .overview-content .content {
  padding-right: 30px;
}
.overview-box .overview-content .content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.overview-box .overview-content .content h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 31px;
}
.overview-box .overview-content .content .features-text {
  margin-top: 25px;
}
.overview-box .overview-content .content .features-text h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.overview-box .overview-content .content .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: var(--mainColor);
}
.overview-box .overview-content .content.right-content {
  padding-left: 30px;
  padding-right: 0;
}
.overview-box .overview-content .content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.overview-box .overview-content .content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.overview-box .overview-content .content .features-list li span {
  display: block;
  background-color: #f8faff;
  border-radius: 5px;
  padding: 13px 12px 8px 40px;
  z-index: 1;
  position: relative;
  transition: var(--transition);
  font-weight: 600;
  font-size: 15.5px;
}
.overview-box .overview-content .content .features-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background-color: var(--mainColor);
  z-index: -1;
  transition: var(--transition);
}
.overview-box .overview-content .content .features-list li span i {
  font-size: 12px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  width: 23px;
  height: 23px;
  line-height: 23px;
  transition: var(--transition);
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.overview-box .overview-content .content .features-list li span:hover {
  color: var(--whiteColor);
}
.overview-box .overview-content .content .features-list li span:hover::before {
  width: 100%;
}
.overview-box .overview-content .content .features-list li span:hover i {
  background-color: var(--optionalColor);
}
.overview-box .overview-image {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: calc(var(--bs-gutter-x) / 2);
  padding-right: calc(var(--bs-gutter-x) / 2);
}
.overview-box .overview-image .image {
  text-align: center;
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
  position: relative;
  z-index: 1;
  background: var(--mainColor);
}
.fun-facts-area::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  height: 100%;
  z-index: -1;
  background-image: url(assets/img/bg_lines.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.single-fun-facts {
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 30px;
  border: 1px dashed rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  transition: var(--transition);
}
.single-fun-facts h3 {
  position: relative;
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 700;
}
.single-fun-facts h3 .sign-icon {
  display: inline-block;
  font-size: 18px;
  margin-left: 2px;
}
.single-fun-facts p {
  line-height: initial;
  margin-top: 5px;
  color: var(--whiteColor);
  font-size: 17px;
  font-weight: 600;
}
.single-fun-facts .back-text {
  position: absolute;
  left: 0;
  color: var(--whiteColor);
  right: 0;
  top: -5px;
  z-index: -1;
  opacity: 0.13;
  line-height: initial;
  font-size: 150px;
  font-weight: 700;
  font-style: italic;
}
.single-fun-facts:hover {
  background-color: var(--mainColor);
  transform: translateY(-10px);
  border-color: var(--whiteColor);
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
}

.single-projects-box {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.single-projects-box .projects-content {
  position: absolute;
  left: 0;
  bottom: 15px;
  background-color: var(--whiteColor);
  height: auto;
  width: 100%;
  padding: 25px 20px 20px;
  transition: transform 0.3s ease, opacity 0.3s;
  max-width: 370px;
  transform: translate3d(-100%, 0, 0);
}
.single-projects-box .projects-content h3 {
  margin-bottom: 0;
  transform: translateX(20px);
  transition: transform 0.3s ease, opacity 0.3s;
  opacity: 0;
  font-size: 24px;
  font-weight: 700;
}
.single-projects-box .projects-content .category {
  display: block;
  margin-top: 8px;
  font-size: var(--fontSize);
  color: var(--paragraphColor);
  opacity: 0;
  transform: translateX(20px);
  transition: transform 0.4s ease;
}
.single-projects-box .plus-icon a {
  background-color: var(--mainColor);
  width: 85px;
  height: 85px;
  position: absolute;
  top: 25px;
  right: 25px;
  transform: scale(0);
  transition: all 0.4s ease-out 0s;
  overflow: hidden;
  border-radius: 50%;
}
.single-projects-box .plus-icon a span {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-projects-box .plus-icon a span::after, .single-projects-box .plus-icon a span::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--whiteColor);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.single-projects-box .plus-icon a span::after {
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.single-projects-box .plus-icon a:hover {
  background-color: var(--optionalColor);
}
.single-projects-box:hover .projects-content {
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0);
}
.single-projects-box:hover .projects-content h3 {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease 0.5s, opacity 0.5s 0.3s;
}
.single-projects-box:hover .projects-content .category {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease 0.6s, opacity 0.5s 0.6s;
}
.single-projects-box:hover .plus-icon a {
  transform: scale(1);
}

.projects-slides.owl-theme .owl-nav.disabled + .owl-dots {
  line-height: 0.01;
  margin-top: 10px;
  margin-bottom: 30px;
}
.projects-slides.owl-theme .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  margin: 0 5px;
  background: #d6d6d6;
  display: block;
  transition: var(--transition);
  border-radius: 50%;
}
.projects-slides.owl-theme .owl-dots .owl-dot:hover span, .projects-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

/*================================================
Projects Details Area CSS
=================================================*/
.project-details-image {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
.project-details-image img {
  transition: var(--transition);
}
.project-details-image a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 75px;
  height: 75px;
  line-height: 75px;
  font-size: 30px;
  background-color: var(--mainColor);
  border-radius: 50%;
  text-align: center;
  color: var(--whiteColor);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
}
.project-details-image a i.fas.fa-plus {
  position: relative;
  top: 4px;
}
.project-details-image a:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.project-details-image:hover a {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}
.project-details-image:hover img {
  transform: scale(1.3) rotate(4deg);
}

.projects-details-desc {
  margin-top: 5px;
}
.projects-details-desc h3 {
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 700;
}
.projects-details-desc .features-text {
  margin-top: 25px;
  margin-bottom: 25px;
}
.projects-details-desc .features-text h4 {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
}
.projects-details-desc .features-text h4 i {
  font-size: 16px;
  margin-right: 4px;
  color: var(--mainColor);
}
.projects-details-desc .project-details-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 35px;
}
.projects-details-desc .project-details-info .single-info-box {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
.projects-details-desc .project-details-info .single-info-box h4 {
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
}
.projects-details-desc .project-details-info .single-info-box span {
  display: block;
  color: var(--paragraphColor);
  font-size: 15px;
}
.projects-details-desc .project-details-info .single-info-box .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.projects-details-desc .project-details-info .single-info-box .social li {
  display: inline-block;
  margin-right: 10px;
}
.projects-details-desc .project-details-info .single-info-box .social li a {
  color: var(--paragraphColor);
  display: inline-block;
}
.projects-details-desc .project-details-info .single-info-box .social li a:hover {
  color: var(--mainColor);
  transform: translateY(-5px);
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

.features-list-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 40px;
}
.features-list-tab .tabs li {
  -ms-flex: 0 0 16.6666666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}
.features-list-tab .tabs li a {
  display: block;
  border-radius: 2px;
  box-shadow: 0px 10px 40px 0px rgba(107, 125, 179, 0.11);
  padding-right: 18px;
  padding-top: 15px;
  padding-bottom: 18px;
  padding-left: 18px;
  font-size: 17px;
  font-weight: 700;
}
.features-list-tab .tabs li a i {
  color: var(--mainColor);
  transition: var(--transition);
  font-weight: normal;
  font-size: 40px;
}
.features-list-tab .tabs li a.bg-fa7070 i {
  color: #fa7070;
}
.features-list-tab .tabs li a.bg-00aeff i {
  color: #00aeff;
}
.features-list-tab .tabs li a.bg-c679e3 i {
  color: #c679e3;
}
.features-list-tab .tabs li a.bg-eb6b3d i {
  color: #eb6b3d;
}
.features-list-tab .tabs li a.bg-f78acb i {
  color: #f78acb;
}
.features-list-tab .tabs li a span {
  display: block;
  margin-top: 3px;
}
.features-list-tab .tabs li.current a, .features-list-tab .tabs li:hover a {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 255, 0.22);
}
.features-list-tab .tabs li.current a i, .features-list-tab .tabs li:hover a i {
  color: var(--whiteColor);
}
.features-list-tab .features-overview {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.features-list-tab .features-overview .overview-content {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.features-list-tab .features-overview .overview-content .content {
  padding-left: 15px;
  padding-right: 15px;
}
.features-list-tab .features-overview .overview-content .content .sub-title {
  color: var(--mainColor);
  display: block;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
}
.features-list-tab .features-overview .overview-content .content h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 30px;
}
.features-list-tab .features-overview .overview-content .content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.features-list-tab .features-overview .overview-content .content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.features-list-tab .features-overview .overview-content .content .features-list li span {
  display: block;
  background-color: #f8faff;
  border-radius: 5px;
  padding: 13px 12px 8px 40px;
  z-index: 1;
  position: relative;
  transition: var(--transition);
  font-weight: 600;
  font-size: 15.5px;
}
.features-list-tab .features-overview .overview-content .content .features-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background-color: var(--mainColor);
  z-index: -1;
  transition: var(--transition);
}
.features-list-tab .features-overview .overview-content .content .features-list li span i {
  font-size: 12px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  width: 23px;
  height: 23px;
  line-height: 23px;
  transition: var(--transition);
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.features-list-tab .features-overview .overview-content .content .features-list li span:hover {
  color: var(--whiteColor);
}
.features-list-tab .features-overview .overview-content .content .features-list li span:hover::before {
  width: 100%;
}
.features-list-tab .features-overview .overview-content .content .features-list li span:hover i {
  background-color: var(--whiteColor);
  color: var(--optionalColor);
}
.features-list-tab .features-overview .overview-image {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.features-list-tab .features-overview .overview-image .image {
  padding-left: 15px;
  padding-right: 15px;
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-team-box {
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: var(--whiteColor);
  transition: var(--transition);
  text-align: center;
}
.single-team-box .image {
  position: relative;
  overflow: hidden;
}
.single-team-box .image img {
  transition: var(--transition);
}
.single-team-box .image .social {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-decoration: none;
  opacity: 0;
  margin-top: -100px;
  padding: 10px 15px 5px;
  background: var(--whiteColor);
  border-radius: 35px;
  visibility: hidden;
  transition: var(--transition);
}
.single-team-box .image .social a {
  display: inline-block;
  color: var(--mainColor);
  font-size: 18px;
  margin: 0 6px;
}
.single-team-box .image .social a:hover {
  color: var(--optionalColor);
}
.single-team-box .content {
  padding: 25px;
  position: relative;
  transition: var(--transition);
}
.single-team-box .content h3 {
  margin-bottom: 0;
  transition: var(--transition);
  font-size: 24px;
  font-weight: 700;
}
.single-team-box .content span {
  display: block;
  margin-top: 8px;
  color: var(--mainColor);
  transition: var(--transition);
  font-weight: 700;
}
.single-team-box:hover {
  transform: translateY(-5px);
}
.single-team-box:hover .image img {
  transform: scale(1.2);
}
.single-team-box:hover .image .social {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}
.single-team-box:hover .content {
  background-color: var(--mainColor);
}
.single-team-box:hover .content h3 {
  color: var(--whiteColor);
}
.single-team-box:hover .content span {
  color: var(--whiteColor);
}

/*================================================
Solution Area CSS
=================================================*/
.solution-area {
  position: relative;
  z-index: 1;
  background-color: var(--mainColor);
  background-image: url(assets/img/solution-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
}
.solution-area.extra-pb {
  padding-bottom: 200px;
}

.solution-content .sub-title {
  color: var(--optionalColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}
.solution-content h2 {
  color: var(--whiteColor);
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 50px;
}
.solution-content p {
  color: #e8e3e3;
}
.solution-content .default-btn {
  margin-top: 10px;
}

.solution-video {
  text-align: center;
}
.solution-video .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  color: var(--mainColor);
  position: relative;
  z-index: 1;
}
.solution-video .video-btn i::before {
  font-size: 38px;
}
.solution-video .video-btn::after, .solution-video .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid var(--whiteColor);
}
.solution-video .video-btn::before {
  animation: ripple 2s linear infinite;
}
.solution-video .video-btn::after {
  animation: ripple 2s linear 1s infinite;
}
.solution-video .video-btn:hover, .solution-video .video-btn:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
}
.feedback-area.bg-color {
  margin-top: -100px;
}
.feedback-area.bg-color .section-title {
  margin-bottom: 30px;
}
.feedback-area.bg-color .section-title .sub-title {
  color: var(--whiteColor);
}
.feedback-area.bg-color .section-title h2 {
  color: var(--whiteColor);
}
.feedback-area.bg-color::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 60%;
  right: 0;
  z-index: -1;
  background-color: var(--mainColor);
  margin-left: 50px;
  margin-right: 50px;
}
.feedback-area.bg-color::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  height: 60%;
  z-index: -1;
  background-image: url(assets/img/bg_lines.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: 50px;
  margin-right: 50px;
}

.single-feedback-item {
  position: relative;
  z-index: 1;
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px #f1f1f1;
  padding: 30px;
  margin-bottom: 30px;
}
.single-feedback-item p {
  color: #565968;
  margin-bottom: 0;
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
}
.single-feedback-item .client-info {
  margin-top: 30px;
  position: relative;
  padding-left: 90px;
}
.single-feedback-item .client-info img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-feedback-item .client-info h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
.single-feedback-item .client-info span {
  display: block;
  color: var(--mainColor);
  margin-top: 3px;
  font-size: 15px;
}

.feedback-slides {
  padding-left: 80px;
  padding-right: 80px;
}
.feedback-slides .single-feedback-item {
  box-shadow: 0px 5px 15px #f1f1f1;
  margin-top: 30px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}
.feedback-slides.owl-theme .owl-nav.disabled + .owl-dots {
  line-height: 0.01;
  margin-top: 10px;
  margin-bottom: 30px;
}
.feedback-slides.owl-theme .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  margin: 0 5px;
  background: #d6d6d6;
  display: block;
  transition: var(--transition);
  border-radius: 50%;
}
.feedback-slides.owl-theme .owl-dots .owl-dot:hover span, .feedback-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-image: url(assets/img/shape-wave.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.single-pricing-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: var(--whiteColor);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 30px 28px 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.single-pricing-box .pricing-header {
  position: relative;
  z-index: 1;
  background-color: var(--mainColor);
  margin: -40px -30px 30px -30px;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 25px;
}
.single-pricing-box .pricing-header h3 {
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.single-pricing-box .price {
  color: var(--blackColor);
  line-height: 50px;
  align-items: baseline;
  position: relative;
  z-index: 1;
  font-size: 50px;
  font-weight: 700;
}
.single-pricing-box .price sub {
  line-height: 16px;
  color: var(--paragraphColor);
  margin: 0 -8px 0 -12px;
  position: relative;
  top: -4px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
}
.single-pricing-box .price-features-list {
  text-align: start;
  padding: 30px 30px;
  margin: 25px -30px 30px -30px;
  list-style-type: none;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
.single-pricing-box .price-features-list li {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 25px;
  color: var(--paragraphColor);
  font-weight: 500;
  font-size: 17px;
}
.single-pricing-box .price-features-list li i {
  font-size: 14px;
  color: var(--mainColor);
  position: absolute;
  right: 0;
  top: 10px;
}
.single-pricing-box .price-features-list li i.flaticon-tick {
  color: #6ac04f;
}
.single-pricing-box .price-features-list li i.flaticon-close {
  color: #dd5252;
  font-size: 13px;
  top: 12px;
}
.single-pricing-box .get-started-btn {
  display: inline-block;
  color: var(--mainColor);
  position: relative;
  font-weight: 600;
  font-size: 18px;
}
.single-pricing-box .get-started-btn::before {
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--mainColor);
  transition: var(--transition);
}
.single-pricing-box .get-started-btn:hover::before {
  width: 100%;
}
.single-pricing-box.red .pricing-header {
  background-color: #fc6765;
}
.single-pricing-box.red .get-started-btn {
  color: #fc6765;
}
.single-pricing-box.red .get-started-btn::before {
  background-color: #fc6765;
}
.single-pricing-box.orange .pricing-header {
  background-color: #ffa143;
}
.single-pricing-box.orange .get-started-btn {
  color: #ffa143;
}
.single-pricing-box.orange .get-started-btn::before {
  background-color: #ffa143;
}
.single-pricing-box:hover {
  transform: translateY(-10px);
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 90px;
}
.partner-area .row {
  margin-left: -5px;
  margin-right: -5px;
}

.single-partner-item {
  text-align: center;
  margin-bottom: 10px;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}
.single-partner-item a {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #f4f7fc;
  border-radius: 5px;
  padding: 20px;
}
.single-partner-item a img:nth-child(1) {
  position: relative;
  display: inline-block !important;
  width: auto !important;
  transition: transform 0.4s ease;
}
.single-partner-item a img:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 200%);
  transition: transform 0.4s ease;
  display: inline-block !important;
  width: auto !important;
}
.single-partner-item a:hover img:nth-child(1) {
  transform: translateY(-200%);
}
.single-partner-item a:hover img:nth-child(2) {
  transform: translate(-50%, -50%);
}

.partner-slides .single-partner-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;
}
.blog-area .pagination-area {
  margin-bottom: 30px;
}

.single-blog-post {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.2);
}
.single-blog-post .post-image {
  overflow: hidden;
}
.single-blog-post .post-image a {
  display: block;
}
.single-blog-post .post-image a img {
  transition: var(--transition);
}
.single-blog-post .post-content {
  padding: 25px;
  position: relative;
  z-index: 1;
}
.single-blog-post .post-content .post-meta {
  transition: var(--transition);
  margin-bottom: 10px;
}
.single-blog-post .post-content .post-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-blog-post .post-content .post-meta ul li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
  color: var(--paragraphColor);
  position: relative;
}
.single-blog-post .post-content .post-meta ul li::before {
  position: absolute;
  right: -11px;
  top: 3px;
  width: 1px;
  height: 13px;
  background-color: var(--paragraphColor);
  content: "";
  transform: rotate(10deg);
}
.single-blog-post .post-content .post-meta ul li a {
  color: var(--optionalColor);
  display: inline-block;
}
.single-blog-post .post-content .post-meta ul li:last-child {
  margin-right: 0;
}
.single-blog-post .post-content .post-meta ul li:last-child::before {
  display: none;
}
.single-blog-post .post-content h3 {
  transition: var(--transition);
  margin-bottom: 8px;
  line-height: 1.4;
  font-size: 24px;
  font-weight: 700;
}
.single-blog-post .post-content h3 a {
  display: inline-block;
}
.single-blog-post .post-content p {
  transition: var(--transition);
  margin-bottom: 0;
}
.single-blog-post .post-content .read-more-btn {
  color: var(--mainColor);
  display: inline-block;
  position: absolute;
  bottom: 15px;
  left: 25px;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 400;
}
.single-blog-post .post-content .read-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 0;
  transition: var(--transition);
  height: 1px;
  background: var(--mainColor);
}
.single-blog-post .post-content .read-more-btn i {
  font-size: 14px;
}
.single-blog-post .post-content .read-more-btn:hover::before {
  width: 100%;
}
.single-blog-post:hover .post-image a img {
  transform: scale(1.2) rotate(4deg);
}
.single-blog-post:hover .post-content .post-meta {
  transform: translateY(-35px);
  opacity: 0;
  visibility: hidden;
}
.single-blog-post:hover .post-content h3 {
  transform: translateY(-35px);
}
.single-blog-post:hover .post-content P {
  transform: translateY(-35px);
}
.single-blog-post:hover .post-content .read-more-btn {
  opacity: 1;
  visibility: visible;
  bottom: 23px;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-content {
  margin-top: 30px;
}
.blog-details-desc .article-content .entry-meta {
  margin-bottom: -8px;
}
.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: var(--blackColor);
  margin-right: 21px;
}
.blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: var(--blackColor);
  font-weight: 500;
}
.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: var(--paragraphColor);
}
.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: var(--mainColor);
}
.blog-details-desc .article-content .entry-meta ul li i {
  color: var(--mainColor);
  margin-right: 2px;
}
.blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: var(--mainColor);
}
.blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 24px;
  font-weight: 700;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}
.blog-details-desc .article-content .features-list li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
  color: var(--paragraphColor);
}
.blog-details-desc .article-content .features-list li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 70% 30% 30% 70%/60% 40% 60% 40%;
  background-color: #faf5f5;
  color: var(--mainColor);
  transition: var(--transition);
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}
.blog-details-desc .article-content .features-list li:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}
.blog-details-desc .article-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details-desc .article-footer .article-tags {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  color: var(--paragraphColor);
}
.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: var(--blackColor);
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: var(--paragraphColor);
}
.blog-details-desc .article-footer .article-tags a:hover {
  color: var(--mainColor);
}
.blog-details-desc .article-footer .article-share {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: end;
  margin-bottom: 0;
}
.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: var(--paragraphColor);
}
.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: var(--mainColor);
  width: 32px;
  height: 32px;
  line-height: 36px;
  border-radius: 50%;
  background-color: #eeeef0;
  text-align: center;
  font-size: 14px;
}
.blog-details-desc .article-footer .article-share .social li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transform: translateY(-2px);
}
.blog-details-desc .post-navigation {
  margin-top: 30px;
}

blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p,
.blockquote p {
  color: var(--blackColor);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 24px !important;
}
blockquote cite,
.blockquote cite {
  display: none;
}
blockquote::before,
.blockquote::before {
  color: #efefef;
  content: "\f10e";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "Font Awesome 5 Free";
  font-size: 140px;
  font-weight: 900;
}
blockquote::after,
.blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.post-navigation .navigation-links {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.post-navigation .navigation-links .nav-previous {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
  transition: var(--transition);
}
.post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 0;
}
.post-navigation .navigation-links .nav-next {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: end;
}
.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
  transition: var(--transition);
}
.post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 0;
}
.post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 600;
}

.comments-area {
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: var(--whiteColor);
}
.comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 24px;
  font-weight: 700;
}
.comments-area .comments-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: var(--mainColor);
  top: 50%;
  transform: translateY(-50%);
}
.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .children {
  margin-left: 20px;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: var(--blackColor);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: var(--blackColor);
  display: inline-block;
  padding: 6px 20px 3px;
  border-radius: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 700;
}
.comments-area .comment-body .reply a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}
.comments-area .comment-author {
  font-size: 17px;
  margin-bottom: 0.1em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-weight: 700;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  color: var(--paragraphColor);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.comments-area .comment-metadata a {
  color: var(--paragraphColor);
}
.comments-area .comment-metadata a:hover {
  color: var(--mainColor);
}
.comments-area .comment-respond {
  margin-top: 30px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  position: relative;
  font-size: 24px;
  font-weight: 700;
}
.comments-area .comment-respond .comment-reply-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: var(--mainColor);
  top: 50%;
  transform: translateY(-50%);
}
.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  margin-bottom: 0;
  margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: block;
  font-weight: 600;
  color: var(--blackColor);
  margin-bottom: 5px;
}
.comments-area .comment-respond input[type=date],
.comments-area .comment-respond input[type=time],
.comments-area .comment-respond input[type=datetime-local],
.comments-area .comment-respond input[type=week],
.comments-area .comment-respond input[type=month],
.comments-area .comment-respond input[type=text],
.comments-area .comment-respond input[type=email],
.comments-area .comment-respond input[type=url],
.comments-area .comment-respond input[type=password],
.comments-area .comment-respond input[type=search],
.comments-area .comment-respond input[type=tel],
.comments-area .comment-respond input[type=number],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: var(--transition);
}
.comments-area .comment-respond input[type=date]:focus,
.comments-area .comment-respond input[type=time]:focus,
.comments-area .comment-respond input[type=datetime-local]:focus,
.comments-area .comment-respond input[type=week]:focus,
.comments-area .comment-respond input[type=month]:focus,
.comments-area .comment-respond input[type=text]:focus,
.comments-area .comment-respond input[type=email]:focus,
.comments-area .comment-respond input[type=url]:focus,
.comments-area .comment-respond input[type=password]:focus,
.comments-area .comment-respond input[type=search]:focus,
.comments-area .comment-respond input[type=tel]:focus,
.comments-area .comment-respond input[type=number]:focus,
.comments-area .comment-respond textarea:focus {
  border-color: var(--mainColor);
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: var(--paragraphColor);
  font-weight: normal;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  padding: 14px 30px 10px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: var(--transition);
  font-weight: 600;
  font-size: 16px;
}
.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: var(--whiteColor);
  background-color: var(--blackColor);
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.subscribe-inner-area {
  position: relative;
  z-index: 1;
  padding: 80px;
  background-color: var(--mainColor);
  background-image: url(assets/img/Hospital-Medical.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.subscribe-inner-area::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: var(--mainColor);
  opacity: 0.8;
}
.subscribe-inner-area::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  height: 100%;
  z-index: -1;
  background-image: url(assets/img/bg_lines.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.subscribe-content {
  text-align: center;
}
.subscribe-content .sub-title {
  color: var(--whiteColor);
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}
.subscribe-content h2 {
  color: var(--whiteColor);
  max-width: 700px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 40px;
}
.subscribe-content .newsletter-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.subscribe-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 70px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
}
.subscribe-content .newsletter-form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.subscribe-content .newsletter-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.subscribe-content .newsletter-form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.subscribe-content .newsletter-form #validator-newsletter {
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  color: var(--whiteColor);
  bottom: -35px;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.page-title-area.page-title-bg1 {
  background: transparent url(assets/img/banner-bg1.jpg) right top no-repeat;
}
.page-title-area.page-title-bg2 {
  background: transparent url(assets/img/banner-bg2.jpg) left top no-repeat;
}
.page-title-area .shape-img7 {
  left: 75%;
}

.page-title-content {
  position: relative;
  margin-top: 70px;
  text-align: center;
}
.page-title-content h2 {
  margin-bottom: 0;
  position: relative;
  padding-bottom: 10px;
  color: white !important;
  font-size: 40px;
  font-weight: 700;
}
.page-title-content h2::before {
  width: 30px;
  height: 2px;
  background-color: var(--optionalColor);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}
.page-title-content ul li {
  position: relative;
  display: inline-block;
  color: var(--mainColor);
  font-size: 17px;
  margin-left: 12px;
  margin-right: 12px;
}
.page-title-content ul li a {
  display: inline-block;
  color: white !important;
}
.page-title-content ul li a:hover {
  color: var(--optionalColor);
}
.page-title-content ul li::before {
  content: "\f054";
  position: absolute;
  right: -18px;
  top: 3px;
  color: var(--paragraphColor);
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
}
.page-title-content ul li:first-child {
  margin-left: 0;
}
.page-title-content ul li:last-child {
  margin-right: 0;
}
.page-title-content ul li:last-child::before {
  display: none;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 40px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 3px;
  display: inline-block;
  background-color: var(--whiteColor);
  line-height: 49px;
  color: var(--blackColor);
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}
.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
  background: var(--mainColor);
  color: var(--whiteColor);
  box-shadow: 0 2px 10px 0 #d8dde6;
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
  font-size: 22px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.widget-area .widget_search {
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: var(--whiteColor);
  padding: 15px;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: var(--transition);
}
.widget-area .widget_search form .search-field:focus {
  border-color: var(--mainColor);
}
.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: var(--transition);
}
.widget-area .widget_search form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.widget-area .widget_karlson_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_karlson_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_karlson_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_karlson_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.widget-area .widget_karlson_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_karlson_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog-image/blog7.jpg);
}
.widget-area .widget_karlson_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog-image/blog8.jpg);
}
.widget-area .widget_karlson_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog-image/blog9.jpg);
}
.widget-area .widget_karlson_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_karlson_posts_thumb .item .info time {
  display: block;
  color: var(--paragraphColor);
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 11px;
}
.widget-area .widget_karlson_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
  font-weight: 700;
}
.widget-area .widget_karlson_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: var(--blackColor);
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 15.5px;
}
.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_entries ul li::before {
  background: var(--mainColor);
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 6px;
}
.widget-area .widget_recent_entries ul li a {
  display: inline-block;
  color: var(--blackColor);
}
.widget-area .widget_recent_entries ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: var(--paragraphColor);
  margin-top: 4px;
}
.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: var(--paragraphColor);
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 15.5px;
}
.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_comments ul li::before {
  background: var(--mainColor);
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 6px;
}
.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: var(--blackColor);
}
.widget-area .widget_recent_comments ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  color: var(--blackColor);
  font-size: 15.5px;
  font-weight: 600;
}
.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_archive ul li::before {
  background: var(--mainColor);
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 6px;
  position: absolute;
}
.widget-area .widget_archive ul li a {
  display: inline-block;
  color: var(--blackColor);
}
.widget-area .widget_archive ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: var(--paragraphColor);
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 600;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 6px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: var(--blackColor);
  display: block;
}
.widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_categories ul li .post-count {
  float: right;
}
.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: var(--blackColor);
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 600;
}
.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_meta ul li::before {
  background: var(--mainColor);
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 6px;
  position: absolute;
}
.widget-area .widget_meta ul li a {
  display: inline-block;
  color: var(--blackColor);
}
.widget-area .widget_meta ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}
.widget-area .tagcloud a {
  display: inline-block;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14.5px !important;
  padding: 6px 13px 3px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  padding-top: 170px;
  padding-bottom: 100px;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
  margin-bottom: 15px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  border: none;
  display: block;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: var(--whiteColor);
  margin-bottom: 10px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item:nth-child(2) .accordion-title i, .faq-accordion .accordion .accordion-item:nth-child(4) .accordion-title i, .faq-accordion .accordion .accordion-item:nth-child(6) .accordion-title i, .faq-accordion .accordion .accordion-item:nth-child(8) .accordion-title i {
  background: var(--optionalColor);
}
.faq-accordion .accordion .accordion-title {
  padding: 15px 20px 10px 51px;
  color: var(--blackColor);
  position: relative;
  border: none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: block;
  font-size: var(--fontSize);
  font-weight: 700;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: var(--mainColor);
  color: var(--whiteColor);
}
.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 13px;
}
.faq-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.faq-area .col-lg-6:nth-child(2) .faq-accordion .accordion .accordion-item:nth-child(2) .accordion-title i, .faq-area .col-lg-6:nth-child(2) .faq-accordion .accordion .accordion-item:nth-child(4) .accordion-title i, .faq-area .col-lg-6:nth-child(2) .faq-accordion .accordion .accordion-item:nth-child(6) .accordion-title i, .faq-area .col-lg-6:nth-child(2) .faq-accordion .accordion .accordion-item:nth-child(8) .accordion-title i {
  background: var(--mainColor);
}
.faq-area .col-lg-6:nth-child(2) .faq-accordion .accordion .accordion-title i {
  background: var(--optionalColor);
}

.faq-contact-form {
  padding-left: 30px;
}
.faq-contact-form form .form-control {
  background-color: var(--whiteColor);
  border: none;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  height: 55px;
  font-size: 16px;
}
.faq-contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.faq-contact-form form .row .col-lg-12,
.faq-contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.faq-contact-form form textarea.form-control {
  height: auto;
  padding-top: 20px;
}
.faq-contact-form form .default-btn {
  margin-top: 5px;
  box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
}
.faq-contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}
.faq-contact-form form .help-block ul li {
  color: red;
}
.faq-contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: start !important;
}
.faq-contact-form form #msgSubmit.text-danger, .faq-contact-form form #msgSubmit.text-success {
  margin-top: 8px;
  font-size: 23px;
  font-weight: 700;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area .section-title {
  margin-top: 110px;
}

.contact-form {
  padding-left: 30px;
}
.contact-form form .form-control {
  background-color: var(--whiteColor);
  border: none;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  height: 55px;
  font-size: 16px;
}
.contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.contact-form form .row .col-lg-12,
.contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.contact-form form textarea.form-control {
  height: auto;
  padding-top: 20px;
}
.contact-form form .default-btn {
  margin-top: 5px;
  box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
}
.contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}
.contact-form form .help-block ul li {
  color: red;
}
.contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: start !important;
}
.contact-form form #msgSubmit.text-danger, .contact-form form #msgSubmit.text-success {
  margin-top: 8px;
  font-size: 23px;
  font-weight: 700;
}

.contact-info-box {
  text-align: center;
  border-radius: 5px;
  transition: var(--transition);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 30px;
}
.contact-info-box .icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: #f5f5f5;
  border-radius: 50%;
  font-size: 30px;
  color: var(--mainColor);
  transition: var(--transition);
  margin-bottom: 20px;
}
.contact-info-box h3 {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
}
.contact-info-box p {
  margin-bottom: 0;
}
.contact-info-box p a {
  display: inline-block;
  color: var(--paragraphColor);
}
.contact-info-box p a:hover {
  color: var(--mainColor);
}
.contact-info-box:hover {
  transform: translateY(-10px);
}
.contact-info-box:hover .icon {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding-top: 80px;
  background-color: var(--blackColor);
}

.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget h3 {
  margin-bottom: 35px;
  color: var(--whiteColor);
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid #4e5e6a;
  font-size: 24px;
  font-weight: 700;
}
.single-footer-widget h3::before {
  bottom: -1px;
  left: 0;
  width: 55px;
  height: 1px;
  background-color: var(--whiteColor);
  content: "";
  position: absolute;
}
.single-footer-widget .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .footer-contact-info li {
  color: var(--whiteColor);
  padding-left: 55px;
  position: relative;
  margin-bottom: 25px;
  font-size: 14.5px;
}
.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-contact-info li i {
  font-size: 35px;
  color: var(--optionalColor);
  position: absolute;
  left: 0;
  top: 0;
}
.single-footer-widget .footer-contact-info li span {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.single-footer-widget .footer-contact-info li a {
  display: inline-block;
  color: var(--whiteColor);
  font-size: 22px;
  font-weight: 700;
}
.single-footer-widget .footer-contact-info li a:hover {
  color: var(--optionalColor);
}
.single-footer-widget .footer-contact-info li .social {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .footer-contact-info li .social li {
  display: inline-block;
  padding-left: 0;
  margin-right: 15px;
  margin-bottom: 0;
}
.single-footer-widget .footer-contact-info li .social li a {
  display: inline-block;
}
.single-footer-widget .footer-contact-info li .social li a i {
  position: relative;
  color: var(--whiteColor);
  font-size: 17px;
  transition: var(--transition);
}
.single-footer-widget .footer-contact-info li .social li a:hover {
  transform: translateY(-2px);
}
.single-footer-widget .footer-contact-info li .social li a:hover i {
  color: var(--optionalColor);
}
.single-footer-widget .footer-contact-info li .social li:last-child {
  margin-right: 0;
}
.single-footer-widget .footer-quick-links {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-bottom: 0;
  margin-top: -15px;
}
.single-footer-widget .footer-quick-links li {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: calc(var(--bs-gutter-x) / 2);
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-top: 15px;
}
.single-footer-widget .footer-quick-links li a {
  display: inline-block;
  color: var(--whiteColor);
}
.single-footer-widget .footer-quick-links li a:hover {
  color: var(--optionalColor);
}
.single-footer-widget .footer-instagram-post {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 0;
  margin-top: -10px;
}
.single-footer-widget .footer-instagram-post li {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.single-footer-widget .footer-instagram-post li a {
  display: block;
}
.single-footer-widget .footer-instagram-post li a:hover {
  opacity: 0.6;
}

.copyright-area {
  border-top: 1px solid #4e5e6a;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-area p {
  color: var(--whiteColor);
  margin-bottom: 0;
}
.copyright-area p a {
  display: inline-block;
  color: var(--whiteColor);
}
.copyright-area p a:hover {
  color: var(--optionalColor);
}
.copyright-area ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  text-align: end;
}
.copyright-area ul li {
  display: inline-block;
  margin: 0 10px;
  color: var(--whiteColor);
  position: relative;
}
.copyright-area ul li a {
  display: inline-block;
  color: var(--whiteColor);
}
.copyright-area ul li a:hover {
  color: var(--optionalColor);
}
.copyright-area ul li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 3px;
  width: 1px;
  height: 14px;
  background-color: var(--whiteColor);
}
.copyright-area ul li:first-child {
  margin-left: 0;
}
.copyright-area ul li:last-child {
  margin-right: 0;
}
.copyright-area ul li:last-child::before {
  display: none;
}

.it-banner {
  height: 100vh;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #f7f7f7;
}
.it-banner .container.mt-50 {
  margin-top: 50px;
}
.it-banner .banner-btn {
  margin-top: 30px;
}
.it-banner .banner-btn a {
  display: inline-flex;
}
.it-banner .video-btn {
  margin-left: 5px;
  line-height: 40px;
}
.it-banner .video-btn i {
  background-color: #ececff;
  height: 35px;
  width: 35px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.it-banner .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.it-banner .it-banner-image {
  text-align: center;
}
.it-banner .it-banner-image.owl-theme .owl-nav {
  margin: 0;
}

.shape-img11 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.shape-img12 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

/* Animate banner image */
.animate-image {
  position: relative;
}

.animate-image img {
  display: block !important;
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
  margin: auto;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
.service-card-one {
  margin-bottom: 30px;
  border-radius: 10px;
  transition: var(--transition);
}
.service-card-one .icon {
  position: relative;
}
.service-card-one .icon i {
  background-color: #ececff;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  border-radius: 50%;
  font-size: 30px;
  color: var(--mainColor);
  display: inline-block;
  margin-bottom: 25px;
}
.service-card-one .icon .check-icon {
  position: absolute;
  background: transparent;
  width: auto;
  height: auto;
  line-height: 1;
  margin: 0px 0px 20px -20px;
  font-size: 18px;
}
.service-card-one h3 {
  font-size: 20px;
  margin-bottom: 15px;
}
.service-card-one p {
  margin-bottom: -5px;
}
.service-card-one.bg-white {
  padding: 30px;
}
.service-card-one.center {
  text-align: center;
}
.service-card-one:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.08);
}
.service-card-one.gray-bg {
  padding: 30px;
}
.service-card-one.gray-bg:hover {
  background-color: var(--mainColor);
}
.service-card-one.gray-bg:hover .icon .check-icon {
  display: none;
}
.service-card-one.gray-bg:hover h3 {
  color: var(--whiteColor);
}
.service-card-one.gray-bg:hover h3 a {
  color: var(--whiteColor);
}
.service-card-one.gray-bg:hover h3 a:hover {
  text-decoration: underline;
}
.service-card-one.gray-bg:hover p {
  color: var(--whiteColor);
}

.it-overview .overview-content .content .features-list li span i {
  color: var(--mainColor);
  width: auto;
  height: auto;
  background-color: transparent;
  line-height: 1;
  font-size: 22px;
}
.it-overview .overview-content .content .features-list li span:hover i {
  background-color: transparent;
  color: var(--whiteColor);
}
.it-overview .overview-content .content .rm-btn {
  margin-top: 25px;
}
.it-overview .overview-content .content .features-text {
  margin-top: 20px;
}
.it-overview .overview-content .content .features-text h4 {
  margin-bottom: 10px;
}

.works-area .more-work {
  margin-top: 20px;
  text-align: center;
}

.work-card {
  margin-bottom: 30px;
  position: relative;
  transition: var(--transition);
}
.work-card::before {
  content: "";
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  width: 100%;
  height: 90%;
  left: 0;
  bottom: 0;
  position: absolute;
  border-radius: 8px;
  transition: var(--transition);
  z-index: 1;
}
.work-card:hover::before {
  background-color: rgba(0, 0, 0, 0.8);
}
.work-card img {
  border-radius: 8px;
  width: 100%;
}
.work-card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 30px 35px;
  transition: var(--transition);
  z-index: 5;
}
.work-card .content span {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}
.work-card .content span a {
  color: var(--whiteColor);
}
.work-card .content h3 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.work-card .content .work-btn {
  background-color: var(--whiteColor);
  color: var(--mainColor);
  padding: 10px 20px 5px;
  border-radius: 30px;
  font-size: 15px;
}
.work-card .content .work-btn:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}
.work-card .content a {
  color: var(--whiteColor);
}
.work-card:hover::before {
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 255, 0), blue);
}
.work-card:hover .content {
  bottom: 20px;
}

.team-slider .single-team-box {
  box-shadow: none;
}
.team-slider .single-team-box .content {
  border-bottom: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
}
.team-slider .single-team-box:hover .content {
  border-bottom: 1px solid var(--mainColor);
  border-left: 1px solid var(--mainColor);
  border-right: 1px solid var(--mainColor);
}
.team-slider.owl-theme .owl-nav {
  margin-top: 0;
}
.team-slider.owl-theme .owl-nav button.owl-prev {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 30px;
  background: #fff;
  height: 30px;
  margin: 0;
  border-radius: 0;
  line-height: 30px;
}
.team-slider.owl-theme .owl-nav button.owl-prev:hover {
  background-color: var(--mainColor);
}
.team-slider.owl-theme .owl-nav button.owl-next {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  background: #fff;
  height: 30px;
  margin: 0;
  border-radius: 0;
  line-height: 30px;
}
.team-slider.owl-theme .owl-nav button.owl-next:hover {
  background-color: var(--mainColor);
}

.pricing-area-two {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.single-pricing-table {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  padding-bottom: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.single-pricing-table .pricing-header {
  padding: 20px 40px;
}
.single-pricing-table .pricing-header .icon {
  background-color: #ececff;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  margin: 0 auto 25px;
}
.single-pricing-table .pricing-header .icon img {
  max-width: 70px;
}
.single-pricing-table .pricing-header h3 {
  margin-bottom: -5px;
  font-size: 20px;
}
.single-pricing-table .pricing-header p {
  margin: 10px 0 -10px;
  font-size: 15px;
}
.single-pricing-table .price {
  color: var(--blackColor);
  transition: var(--transition);
  padding: 10px 40px 0;
  border-top: 2px dashed #f4f5fe;
  border-bottom: 2px dashed #f4f5fe;
  font-size: 40px;
  font-weight: 900;
}
.single-pricing-table .price sup {
  top: -18px;
  font-size: 18px;
  font-weight: 500;
}
.single-pricing-table .price sub {
  bottom: 2px;
  color: #4a6f8a;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 500;
}
.single-pricing-table .pricing-features {
  margin: 0;
  padding: 30px 40px;
  list-style-type: none;
}
.single-pricing-table .pricing-features li {
  margin-bottom: 10px;
  position: relative;
  color: #444683;
  padding-left: 25px;
}
.single-pricing-table .pricing-features li:last-child {
  margin-bottom: 0;
}
.single-pricing-table .pricing-features li i {
  color: var(--mainColor);
  position: absolute;
  left: 0;
  top: 2px;
}
.single-pricing-table .pricing-features li span {
  display: inline-block;
  color: #b7bad2;
  font-size: 20px;
  position: absolute;
  top: 1px;
  margin-left: 3px;
}
.single-pricing-table .btn-box {
  padding: 0 40px;
}
.single-pricing-table:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  transform: translateY(-10px);
}
.single-pricing-table.center {
  text-align: center;
}
.single-pricing-table.center .pricing-features {
  text-align: left;
}
.single-pricing-table.center .pricing-features li {
  padding-left: 0;
  padding-right: 25px;
}
.single-pricing-table.center .pricing-features li i {
  left: auto;
  right: 0;
}
.single-pricing-table.center .default-btn {
  display: block;
  width: 100%;
}

.lets-work .sub-title {
  color: var(--whiteColor);
  display: block;
  margin-bottom: 10px;
}
.lets-work h2 {
  color: var(--whiteColor);
  font-size: 60px;
  font-weight: 900;
}
.lets-work .contact-btn {
  text-align: right;
}
.lets-work .contact-btn .default-btn {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.25);
}
.lets-work .contact-btn .default-btn span {
  background-color: var(--optionalColor);
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
  transform: translate(0%, 0%);
}
.lets-work .contact-btn .default-btn:hover span {
  background-color: #000;
}

.feedback-area-two .section-title {
  margin-bottom: 40px;
}
.feedback-area-two .feedback-slides .single-feedback-item {
  margin-top: 15px;
}
.feedback-area-two .feedback-slides .single-feedback-item.border {
  border: 1px solid #eee;
  border-radius: 10px;
}

/*================================================
End IT Startup Page CSS
=================================================*/
/*================================================
SEO Startup Page CSS
=================================================*/
.seo-banner-slider img {
  width: auto !important;
  display: inline-block !important;
}
.seo-banner-slider.owl-theme .owl-nav {
  margin: 0;
}
.seo-banner-slider.owl-theme .owl-nav button.owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 40px;
  margin: 0;
  border-radius: 0;
  line-height: 40px;
  transform: translateY(-50%);
  border-radius: 0 5px 5px 0;
  transition: var(--transition);
}
.seo-banner-slider.owl-theme .owl-nav button.owl-prev:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}
.seo-banner-slider.owl-theme .owl-nav button.owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  width: 40px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  height: 40px;
  margin: 0;
  border-radius: 0;
  transform: translateY(-50%);
  line-height: 40px;
  border-radius: 5px 0 0 5px;
  transition: var(--transition);
}
.seo-banner-slider.owl-theme .owl-nav button.owl-next:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.seo-banner {
  height: 100vh;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #f7f7f7;
}
.seo-banner .container.mt-50 {
  margin-top: 50px;
}
.seo-banner .banner-btn {
  margin-top: 30px;
}
.seo-banner .banner-btn a {
  display: inline-flex;
}
.seo-banner .video-btn {
  margin-left: 5px;
  line-height: 40px;
}
.seo-banner .video-btn i {
  background-color: #ececff;
  height: 35px;
  width: 35px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.seo-banner .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.seo-banner .seo-banner-image {
  text-align: center;
}
.seo-banner .seo-banner-image.animate-tb {
  animation: moveBounce 5s linear infinite;
}

.shape-img13 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.shape-img14 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.rounded-10 {
  border-radius: 10px;
}

.seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(2) {
  margin-top: 30px;
}
.seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(3) {
  margin-top: -30px;
}
.seo-featured-services .col-lg-7 .row .col-lg-6:nth-child(5) {
  margin-top: -30px;
}

.about-area-two .section-title {
  margin-bottom: 30px;
  max-width: 525px;
  margin-left: 0;
  width: 100%;
}
.about-area-two .section-title h2 {
  max-width: 100%;
  margin-bottom: 0;
}
.about-area-two .about-image {
  position: relative;
}
.about-area-two .solution-video {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
}
.about-area-two .solution-video .video-btn:hover::after, .about-area-two .solution-video .video-btn:hover::before {
  border: 1px solid transparent;
}
.about-area-two .about-content {
  padding-left: 20px;
}
.about-area-two .about-text {
  margin-bottom: 20px;
}
.about-area-two .about-text:last-child {
  margin-bottom: 0;
}
.about-area-two .about-text h4 {
  font-size: 20px;
}

.works-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1920px;
}

.work-slider .work-card {
  margin-bottom: 0;
}
.work-slider.owl-theme .owl-nav {
  margin-top: 0;
}
.work-slider.owl-theme .owl-nav button.owl-prev {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 30px;
  background: #fff;
  height: 30px;
  margin: 0;
  border-radius: 0;
  line-height: 30px;
}
.work-slider.owl-theme .owl-nav button.owl-prev:hover {
  background-color: var(--mainColor);
}
.work-slider.owl-theme .owl-nav button.owl-next {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  background: #fff;
  height: 30px;
  margin: 0;
  border-radius: 0;
  line-height: 30px;
}
.work-slider.owl-theme .owl-nav button.owl-next:hover {
  background-color: var(--mainColor);
}

.choose-area-two .section-title {
  margin-bottom: 30px;
  max-width: 100%;
}
.choose-area-two .section-title h2 {
  font-size: 36px;
  margin-left: 0;
}
.choose-area-two .choose-text {
  position: relative;
  padding-left: 40px;
  margin-bottom: 25px;
}
.choose-area-two .choose-text:last-child {
  margin-bottom: 0;
}
.choose-area-two .choose-text i {
  position: absolute;
  font-size: 30px;
  color: var(--mainColor);
  left: 0;
  top: 0;
}
.choose-area-two .choose-text h4 {
  font-size: 20px;
}

.features-area .tabs_item.bg-white {
  padding: 20px;
  border: 1px solid #f7f7f7;
}

.red {
  color: red !important;
}

.seo-analysis-section .section-title {
  max-width: 100%;
  margin-bottom: 30px;
}
.seo-analysis-section .analysis-img {
  text-align: center;
}

.analysis-form .form-group {
  margin-bottom: 25px;
}
.analysis-form .form-control {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding-left: 20px;
  font-size: 15px;
  color: #9d9fad;
  line-height: 30px;
  height: 56px;
}
.analysis-form button.default-btn {
  margin-top: 10px;
}

/*================================================
End SEO Startup Page CSS
=================================================*/
/*================================================
SaaS Startup Page CSS
=================================================*/
.default-btn-two {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: var(--whiteColor);
  text-transform: uppercase;
  background-color: var(--optionalColor);
  transition: var(--transition);
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 17px;
  padding-bottom: 13px;
}
.default-btn-two span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--mainColor);
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}
.default-btn-two:hover, .default-btn-two:focus {
  color: var(--whiteColor);
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.25);
  transform: translateY(-4px);
}
.default-btn-two:hover span, .default-btn-two:focus span {
  width: 225%;
  height: 562.5px;
}

.saas-banner-two {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #f1ece4;
  z-index: 0;
}
.saas-banner-two .container.mt-50 {
  margin-top: 50px;
}
.saas-banner-two .banner-btn {
  margin-top: 30px;
}
.saas-banner-two .banner-btn a {
  display: inline-flex;
}
.saas-banner-two .saas-banner-image {
  text-align: center;
  position: relative;
}
.saas-banner-two .saas-banner-image img {
  border-radius: 10px;
}
.saas-banner-two .saas-banner-image.animate-tb {
  animation: moveBounce 5s linear infinite;
}
.saas-banner-two .saas-banner-image .solution-video {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
}
.saas-banner-two .saas-banner-image .solution-video .video-btn {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.saas-banner-two .saas-banner-image .solution-video .video-btn:hover::after, .saas-banner-two .saas-banner-image .solution-video .video-btn:hover::before {
  border: 1px solid transparent;
}
.saas-banner-two .saas-banner-image .solution-video .video-btn::after, .saas-banner-two .saas-banner-image .solution-video .video-btn::before {
  border: 1px solid var(--mainColor);
}

.service-card-one .icon .number {
  background-color: var(--mainColor);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  line-height: 22px;
  position: absolute;
  margin: 50px 0 0 -25px;
}

.bg-f2f2f7 {
  background-color: #f2f2f7;
}

.fun-fact-card {
  margin-bottom: 30px;
  text-align: center;
  background: #fff;
  padding: 30px 10px;
  border-radius: 10px;
  transition: var(--transition);
}
.fun-fact-card i {
  background-color: #ececff;
  color: var(--mainColor);
  font-size: 35px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.fun-fact-card h3 {
  font-size: 40px;
  font-weight: 800;
}
.fun-fact-card:hover {
  transform: translateY(-5px);
}

/*================================================
End SEO Startup Page CSS
=================================================*/
/*================================================
AI & Machine Learning Page CSS
=================================================*/
.machine-learning-banner {
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-size: cover;
  background-position: center center;
}
.machine-learning-banner .banner-content h1 {
  color: var(--whiteColor);
}
.machine-learning-banner .banner-content p {
  color: var(--whiteColor);
}
.machine-learning-banner .banner-btn {
  margin-top: 30px;
}
.machine-learning-banner .banner-btn a {
  display: inline-flex;
}
.machine-learning-banner .ml-video {
  position: relative;
  text-align: center;
}
.machine-learning-banner .solution-video {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
}
.machine-learning-banner .solution-video .video-btn {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}
.machine-learning-banner .solution-video .video-btn:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.machine-learning-banner .solution-video .video-btn:hover::after, .machine-learning-banner .solution-video .video-btn:hover::before {
  border: 1px solid transparent;
}
.machine-learning-banner .solution-video .video-btn::after, .machine-learning-banner .solution-video .video-btn::before {
  border: 1px solid var(--whiteColor);
}

.ml-bg1 {
  background-image: url("assets/img/machine-learning/slider/ml-slider1.jpg");
}

.ml-bg2 {
  background-image: url("assets/img/machine-learning/slider/ml-slider2.jpg");
}

.ml-bg3 {
  background-image: url("assets/img/machine-learning/slider/ml-slider3.jpg");
}

.machine-learning-slider .owl-item img {
  display: inline-block;
  width: auto;
}
.machine-learning-slider.owl-theme .owl-nav {
  margin: 0;
}
.machine-learning-slider.owl-theme .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--whiteColor);
  width: 40px;
  height: 40px;
  margin: 0;
  color: var(--mainColor);
  padding: 0;
  border-radius: 0;
  border-radius: 0 5px 5px 0;
  transition: var(--transition);
}
.machine-learning-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--whiteColor);
  width: 40px;
  height: 40px;
  margin: 0;
  color: var(--mainColor);
  padding: 0;
  border-radius: 0;
  border-radius: 5px 0 0 5px;
  transition: var(--transition);
}
.machine-learning-slider.owl-theme .owl-nav .owl-prev:hover,
.machine-learning-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.machine-learning-slider.owl-theme .owl-dots {
  position: absolute;
  bottom: 15px;
  left: 60px;
  right: 60px;
}
.machine-learning-slider.owl-theme .owl-dots .owl-dot.active span,
.machine-learning-slider.owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor);
}

.featured-solution-card {
  box-shadow: 0px 5px 20px 0px rgba(105, 146, 234, 0.14);
  transition: var(--transition);
  padding: 30px 25px;
  margin-bottom: 30px;
}
.featured-solution-card i {
  color: var(--mainColor);
  font-size: 35px;
  display: inline-block;
  margin-bottom: 20px;
}
.featured-solution-card h3 {
  font-size: 19px;
  line-height: 1.3;
}
.featured-solution-card h3 a {
  color: var(--blackColor);
}
.featured-solution-card h3 a:hover {
  color: var(--mainColor);
}
.featured-solution-card p {
  margin: 0;
  font-size: 15px;
}
.featured-solution-card .read-more {
  color: var(--mainColor);
  font-size: 14.5px;
  margin-top: 15px;
  position: relative;
  font-weight: 600;
}
.featured-solution-card .read-more::after {
  content: "";
  background-color: var(--mainColor);
  height: 1px;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: var(--transition);
}
.featured-solution-card .read-more:hover::after {
  width: 100%;
}

.ml-services-area .section-title {
  max-width: 100%;
}
.ml-services-area .section-title h2 {
  max-width: 100%;
}
.ml-services-area .section-title p {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ml-service {
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  padding: 30px;
  overflow: hidden;
  position: relative;
  transition: var(--transition);
}
.ml-service .image {
  margin-bottom: 30px;
}
.ml-service h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.ml-service h3 a {
  color: var(--blackColor);
}
.ml-service h3 a:hover {
  color: var(--mainColor);
}
.ml-service p {
  margin: 0;
  font-size: 15px;
}
.ml-service .read-more {
  color: var(--mainColor);
  font-size: 15px;
  margin-top: 15px;
  position: relative;
  font-weight: 600;
}
.ml-service .read-more::after {
  content: "";
  background-color: var(--mainColor);
  height: 1px;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: var(--transition);
}
.ml-service .read-more:hover::after {
  width: 100%;
}
.ml-service::before {
  content: "";
  background-color: var(--mainColor);
  position: absolute;
  top: -100%;
  left: -100%;
  width: 90px;
  height: 50px;
  transform: rotate(135deg);
  transition: var(--transition);
}
.ml-service:hover {
  transition: var(--transition);
}
.ml-service:hover::before {
  top: -32px;
  left: -34px;
}

.free-trial-area .subscribe-content .sub-title {
  color: var(--mainColor);
}
.free-trial-area .subscribe-content h2 {
  color: var(--blackColor);
}
.free-trial-area .subscribe-content .help-text {
  font-size: 15px;
  margin: 15px 0 0;
  font-style: italic;
}
.free-trial-area .subscribe-content .validation-danger {
  color: red !important;
  font-size: 15px;
}

/*================================================
End AI & Machine Learning Page CSS
=================================================*/
/*================================================
Portfolio Agency Page CSS
=================================================*/
.portfolio-agency-banner3 {
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-size: cover;
  background-position: center center;
}
.portfolio-agency-banner3 .banner-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.portfolio-agency-banner3 .banner-content #yellow {
  color: var(--optionalColor);
  letter-spacing: 0.5rem;
  font-weight: 900;
}
.portfolio-agency-banner3 .banner-content #green {
  color: #3e723b;
  letter-spacing: 0.3rem;
  font-weight: 900;
}
.portfolio-agency-banner3 .banner-content p {
  color: var(--whiteColor);
}
.portfolio-agency-banner3 .banner-btn {
  margin-top: 75px;
}
.portfolio-agency-banner3 .banner-btn a {
  display: inline-flex;
}
.portfolio-agency-banner3 .banner-btn h4 {
  margin-bottom: 5rem;
}

.portfolio-agency-banner1 {
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-size: cover;
  background-position: center center;
}
.portfolio-agency-banner1::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.portfolio-agency-banner1 .banner-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.portfolio-agency-banner1 .banner-content h1 {
  color: var(--whiteColor);
}
.portfolio-agency-banner1 .banner-content p {
  color: var(--blackColor);
}
.portfolio-agency-banner1 .banner-btn {
  margin-top: 200px;
}
.portfolio-agency-banner1 .banner-btn a {
  display: inline-flex;
}

.pa-bg1 {
  background-image: url("assets/img/5.png");
}

.pa-bg2 {
  background-image: url("assets/img/Optimized-contactt.png");
}

.pa-bg3 {
  background-image: url("assets/img/service6.jpg");
}

.pa-bg5 {
  background-image: url("assets/img/imgg.png");
}

/*================================================
End Portfolio Agency Learning Page CSS
=================================================*/
.team-area .max-w-1700 {
  max-width: 1700px;
  padding-left: 30px;
  padding-right: 30px;
}

/*================================================
Login Page CSS
=================================================*/
.form-content {
  background-color: #fafbfd;
  max-width: 500px;
  margin: 100px auto;
  padding: 40px 30px;
}
.form-content .form-header {
  text-align: center;
  margin-bottom: 20px;
}
.form-content .form-header h3 {
  font-weight: 700;
}
.form-content .form-header p {
  margin: 0;
}
.form-content .form-group {
  margin-bottom: 20px;
}
.form-content .form-group.form-check label {
  font-size: 15px;
}
.form-content .form-link {
  font-size: 15px;
  display: inline-block;
}
.form-content .form-control {
  height: 50px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 0;
  transition: 0.5s;
  color: #212529;
  line-height: normal;
  padding-left: 13px;
  font-size: 14.5px;
  padding-top: 10px;
}
.form-content .default-btn {
  display: block;
  width: 100%;
}
.form-content .form-footer {
  margin-top: 20px;
  text-align: center;
}
.form-content .form-footer p {
  margin: 0;
}
.form-content .form-footer p a {
  color: var(--mainColor);
}

/*================================================
End Login Page CSS
=================================================*/
.section-title.with-linear-gradient-text {
  max-width: 725px;
}
.section-title.with-linear-gradient-text h2 {
  max-width: 725px;
}
.section-title.with-linear-gradient-text h2 span {
  background: linear-gradient(89.02deg, #3065ba 68.24%, #8459ea 97.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.section-title.with-line-text {
  max-width: 725px;
}
.section-title.with-line-text .sub-title {
  color: var(--whiteColor);
}
.section-title.with-line-text h2 {
  max-width: 725px;
  color: var(--whiteColor);
}
.section-title.with-line-text h2 span {
  position: relative;
  z-index: 1;
  color: var(--whiteColor);
}
.section-title.with-line-text h2 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.section-title.with-line-text-with-white-color {
  max-width: 725px;
}
.section-title.with-line-text-with-white-color h2 {
  max-width: 725px;
}
.section-title.with-line-text-with-white-color h2 span {
  position: relative;
  z-index: 1;
}
.section-title.with-line-text-with-white-color h2 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}

.background-with-black-color {
  background-color: #1f1f22;
}

/*================================================
Topbar Wrap Area CSS
=================================================*/
.topbar-wrap-area {
  background-color: var(--mainColor);
  padding-top: 15px;
  padding-bottom: 15px;
}
.topbar-wrap-area .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.topbar-social-links {
  padding: 0;
  margin-bottom: 0;
  line-height: 1;
}
.topbar-social-links li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.topbar-social-links li:last-child {
  margin-right: 0;
}
.topbar-social-links li a {
  line-height: 1;
}
.topbar-social-links li a i {
  font-size: 20px;
  color: var(--whiteColor);
  transition: var(--transition);
}
.topbar-social-links li a i:hover {
  color: var(--optionalColor);
  transform: translateY(-2px);
}

.topbar-action-list {
  padding: 0;
  margin-bottom: 0;
  text-align: end;
}
.topbar-action-list li {
  list-style-type: none;
  display: inline-block;
  margin-right: 30px;
  color: var(--whiteColor);
  position: relative;
  padding-left: 22px;
}
.topbar-action-list li::before {
  content: "";
  position: absolute;
  top: 1px;
  right: -20px;
  width: 1px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.18);
}
.topbar-action-list li:last-child {
  margin-right: 0;
}
.topbar-action-list li:last-child::before {
  display: none;
}
.topbar-action-list li i {
  position: absolute;
  left: 0;
  top: 2px;
}
.topbar-action-list li a {
  color: var(--whiteColor);
}
.topbar-action-list li a:hover {
  color: var(--optionalColor);
}

/*================================================
Value Trade Area CSS
=================================================*/
.value-trade-area {
  background-color: #f9f9f9;
  padding-top: 10px;
  padding-bottom: 10px;
}
.value-trade-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.value-trade-card {
  position: relative;
  padding: 16.8px 15px 15px 45px;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
  border-radius: 5px;
}
.value-trade-card img {
  display: inline-block;
  width: auto !important;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.value-trade-card p {
  font-size: 15.5px;
  font-weight: 400;
  margin-bottom: 0;
  font-weight: 400;
  color: var(--paragraphColor);
}
.value-trade-card p b {
  font-weight: 600;
  color: var(--blackColor);
}
.value-trade-card p span {
  font-weight: 600;
  color: #76baaf;
  position: relative;
  margin-left: 15px;
  padding-left: 20px;
}
.value-trade-card p span i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
}
.value-trade-card p .color-two {
  color: #ff6c6c;
}
.value-trade-card p .color-two i {
  color: #ff6c6c;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area-with-position-relative {
  position: relative;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
}
.navbar-area-with-position-relative .karlson-nav .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.navbar-area-with-position-relative.with-black-color {
  background-color: #1f1f22;
  box-shadow: unset;
}
.navbar-area-with-position-relative.with-black-color.is-sticky {
  background-color: #1f1f22 !important;
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item a {
  color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item a:hover, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item a:focus, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item:hover a, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item:focus a, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #696997;
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .cart-items a {
  color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .cart-items a:hover {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .option-item {
  color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .option-item .search-btn {
  color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .option-item .search-btn:hover {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .option-item .close-btn {
  color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .option-item .close-btn:hover {
  color: var(--mainColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .burger-menu span {
  color: var(--whiteColor);
  background-color: var(--whiteColor);
}
.navbar-area-with-position-relative.with-black-color .karlson-nav .navbar .others-options .burger-menu:hover span {
  background: var(--mainColor);
}

/*================================================
Cryptocurrency Banner Area CSS
=================================================*/
.cryptocurrency-banner-section {
  background: #f1f9f9;
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cryptocurrency-banner-section .container-fluid {
  padding-left: 50px;
  padding-right: 0;
}
.cryptocurrency-banner-section::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 545px;
  background: #d3e3e7;
  z-index: -1;
}

.cryptocurrency-banner-content {
  max-width: 620px;
  position: relative;
  top: -50px;
}
.cryptocurrency-banner-content .sub-title {
  color: #2b6def;
  font-size: var(--fontSize);
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
.cryptocurrency-banner-content h1 {
  font-size: 66px;
  font-weight: 800;
  margin-bottom: 15px;
}
.cryptocurrency-banner-content h1 span {
  background: linear-gradient(89.36deg, #2c66b8 0.07%, #8859ec 73.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.cryptocurrency-banner-content p {
  margin-bottom: 0;
}
.cryptocurrency-banner-content .banner-btn {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.cryptocurrency-banner-content .banner-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.cryptocurrency-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.cryptocurrency-banner-content .banner-btn li .video-btn {
  margin-left: 5px;
  font-size: 15.5px;
  font-weight: 600;
  color: var(--blackColor);
}
.cryptocurrency-banner-content .banner-btn li .video-btn i {
  background: rgba(47, 143, 232, 0.07);
  height: 45px;
  width: 45px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.cryptocurrency-banner-content .banner-btn li .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.cryptocurrency-banner-image {
  position: relative;
  z-index: 1;
}
.cryptocurrency-banner-image .cart1 {
  position: absolute;
  bottom: 12%;
  left: -230px;
  bottom: 10%;
  transform: translateY(-12%);
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}
.cryptocurrency-banner-image .cart2 {
  position: absolute;
  top: 5%;
  right: 20%;
  transform: translateY(-5%) translateX(-20%);
  z-index: -1;
  animation: moveLeftBounce 8s linear infinite;
}
.cryptocurrency-banner-image .graph {
  position: absolute;
  left: -70px;
  top: 38%;
  transform: translateY(-38%);
  z-index: -11;
  animation: moveLeftBounce 8s linear infinite;
}

.cryptocurrency-shape-1 {
  position: absolute;
  left: 10%;
  top: -20px;
  transform: translateX(-10%);
  z-index: -1;
  animation: moveLeftBounce 5s linear infinite;
}

.cryptocurrency-shape-2 {
  position: absolute;
  left: 40%;
  top: 100px;
  transform: translateX(-40%);
  z-index: -1;
}
.cryptocurrency-shape-2 img {
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cryptocurrency-shape-3 {
  position: absolute;
  bottom: 15%;
  left: 2%;
  transform: translateY(-15%) translateX(-2%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

.cryptocurrency-shape-4 {
  position: absolute;
  bottom: 45%;
  right: 5%;
  z-index: -1;
  transform: translateY(-45%) translateX(-5%);
}
.cryptocurrency-shape-4 img {
  animation-name: rotateMe;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*================================================
Stats Area CSS
=================================================*/
.stats-fun-fact-box {
  margin-bottom: 30px;
  transition: var(--transition);
}
.stats-fun-fact-box .stats-image {
  margin-bottom: 28px;
}
.stats-fun-fact-box h3 {
  font-size: 30px;
  margin-bottom: 0;
}
.stats-fun-fact-box span {
  font-size: var(--fontSize);
  font-weight: bold;
  color: var(--paragraphColor);
  display: inline-block;
  margin-top: 10px;
}
.stats-fun-fact-box:hover {
  transform: translateY(-5px);
}

/*================================================
Crypto About Area CSS
=================================================*/
.crypto-about-content {
  padding-left: 25px;
}
.crypto-about-content .sub-title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.crypto-about-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
}
.crypto-about-content h3 span {
  background: linear-gradient(89.21deg, #2f66b9 21.64%, #8659eb 71.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.crypto-about-content p {
  margin-bottom: 0;
}
.crypto-about-content .list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.crypto-about-content .list li {
  list-style-type: none;
  font-size: 16.5px;
  font-weight: bold;
  color: var(--paragraphColor);
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.crypto-about-content .list li:last-child {
  margin-bottom: 0;
}
.crypto-about-content .list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #76baaf;
  font-size: 16.5px;
}
.crypto-about-content .list li span {
  color: #29a9e1;
  border-bottom: 1px solid #29a9e1;
}
.crypto-about-content .about-btn {
  margin-top: 28px;
}

/*================================================
Earning Platform Area CSS
=================================================*/
.earning-platform-area {
  background: #f4fafd;
}
.earning-platform-area .section-title {
  max-width: 725px;
}
.earning-platform-area .section-title h2 {
  max-width: 725px;
}

.earning-platform-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.earning-platform-card .earning-image {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  margin-bottom: 28px;
  transition: var(--transition);
}
.earning-platform-card h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.earning-platform-card:hover {
  transform: translateY(-5px);
}

/*================================================
Crypto Get Strated Area CSS
=================================================*/
.crypto-get-strated-area {
  background: linear-gradient(to right, #3565bd, #4365cb, #5663d7, #6b60e1, #835ae9);
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.crypto-get-strated-content {
  padding-left: 25px;
  position: relative;
  top: -50px;
}
.crypto-get-strated-content .content {
  max-width: 650px;
}
.crypto-get-strated-content .content span {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.crypto-get-strated-content .content h3 {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1.4;
  color: var(--whiteColor);
}
.crypto-get-strated-content .crypto-get-strated-card {
  margin-top: 30px;
  margin-left: 5px;
}
.crypto-get-strated-content .crypto-get-strated-card .get-image {
  margin-bottom: 30px;
}
.crypto-get-strated-content .crypto-get-strated-card h3 {
  font-size: 24px;
  margin-bottom: 12px;
  color: var(--whiteColor);
}
.crypto-get-strated-content .crypto-get-strated-card p {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.crypto-get-strated-shape {
  position: absolute;
  top: 10%;
  right: 10%;
  transform: translateY(-10%) translateX(-10%);
  animation: moveBounce 5s linear infinite;
  z-index: -1;
}

/*================================================
Key Features Area CSS
=================================================*/
.key-features-area {
  background: #f4fafd;
}

.key-features-card {
  margin-bottom: 30px;
}
.key-features-card .key-content {
  border-radius: 5px;
  position: relative;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px 30px 30px 125px;
  transition: var(--transition);
  margin-bottom: 30px;
}
.key-features-card .key-content:last-child {
  margin-bottom: 0;
}
.key-features-card .key-content.right-gap {
  position: relative;
  right: 30px;
}
.key-features-card .key-content.left-gap {
  position: relative;
  left: 30px;
}
.key-features-card .key-content .icon-image {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  transition: var(--transition);
  position: absolute;
  left: 25px;
  top: 30px;
}
.key-features-card .key-content .icon-image img {
  max-width: 40px;
}
.key-features-card .key-content h3 {
  font-size: 22px;
  margin-bottom: 12px;
}
.key-features-card .key-content p {
  margin-bottom: 0;
}
.key-features-card .key-content:hover {
  transform: translateY(-5px);
}

/*================================================
Mining Area CSS
=================================================*/
.mining-content {
  padding-left: 50px;
}
.mining-content .sub-title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.mining-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
}
.mining-content h3 span {
  background: linear-gradient(89.21deg, #2f66b9 21.64%, #8659eb 71.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.mining-content p {
  margin-bottom: 0;
}
.mining-content .mining-btn {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.mining-content .mining-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.mining-content .mining-btn li:last-child {
  margin-right: 0;
}
.mining-content .mining-btn li .video-btn {
  margin-left: 5px;
  font-size: 15.5px;
  font-weight: 600;
  color: var(--blackColor);
}
.mining-content .mining-btn li .video-btn i {
  background: rgba(47, 143, 232, 0.07);
  height: 45px;
  width: 45px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.mining-content .mining-btn li .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

/*================================================
Trade Over Area CSS
=================================================*/
.trade-over-inner-box {
  background-color: var(--whiteColor);
  box-shadow: 0px 3px 20px rgba(36, 157, 210, 0.12);
  border-radius: 5px;
  padding-left: 50px;
  padding-right: 50px;
}

.trade-over-card {
  margin-bottom: 30px;
  position: relative;
  padding-left: 65px;
}
.trade-over-card .over-image {
  position: absolute;
  left: 0;
  top: 0;
}
.trade-over-card p {
  font-size: 15.5px;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 400;
  color: var(--paragraphColor);
}
.trade-over-card p b {
  font-weight: 600;
  color: var(--blackColor);
}
.trade-over-card p span {
  font-weight: 500;
  color: var(--whiteColor);
  display: inline-block;
  padding: 8px 10px 5px;
  background: #76baaf;
  box-shadow: -2px 6px 20px rgba(255, 109, 52, 0.07);
  border-radius: 5px;
  font-size: 14.5px;
  margin-left: 10px;
  line-height: 1;
}
.trade-over-card p .color-two {
  background-color: #ff6c6c;
}
.trade-over-card h3 {
  font-size: 30px;
  margin-bottom: 8px;
}
.trade-over-card span {
  color: var(--paragraphColor);
}
.trade-over-card .over-shape {
  position: absolute;
  right: 0;
  top: 0;
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-area {
  background-color: #f4fafd;
}

.why-choose-us-content {
  max-width: 550px;
}
.why-choose-us-content .sub-title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.why-choose-us-content h3 {
  font-size: 40px;
  margin-bottom: 12px;
  line-height: 1.4;
}
.why-choose-us-content h3 span {
  background: linear-gradient(89.21deg, #2f66b9 21.64%, #8659eb 71.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.why-choose-us-content p {
  margin-bottom: 0;
}
.why-choose-us-content .choose-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.why-choose-us-content .choose-list li {
  list-style-type: none;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  position: relative;
  margin-bottom: 20px;
  padding: 15px 15px 15px 40px;
  border-radius: 5px;
  font-size: 16.5px;
  font-weight: bold;
  color: var(--paragraphColor);
  transition: var(--transition);
}
.why-choose-us-content .choose-list li:last-child {
  margin-bottom: 0;
}
.why-choose-us-content .choose-list li::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #76baaf;
  border-radius: 50px;
  left: 20px;
  top: 19.5px;
}
.why-choose-us-content .choose-list li:hover {
  transform: translateY(-2px);
}

.why-choose-us-card {
  background-color: var(--whiteColor);
  border: 1px solid #ebd7d3;
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.why-choose-us-card .image {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  margin-bottom: 28px;
  transition: var(--transition);
}
.why-choose-us-card h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.why-choose-us-card:hover {
  transform: translateY(-5px);
  border: 1px solid var(--mainColor);
}

.choose-with-box-style .col-lg-6:nth-child(2) .why-choose-us-card {
  margin-bottom: 25px;
}

/*================================================
Unique Feedback Area CSS
=================================================*/
.unique-feedback-area {
  background-image: url(assets/img/cryptocurrency-home/unique-feedback-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.unique-feedback-area .section-title .sub-title {
  color: var(--whiteColor);
}
.unique-feedback-area .section-title h2 {
  color: var(--whiteColor);
}
.unique-feedback-area::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 150px;
  width: 100%;
  background-color: #f4fafd;
  z-index: -1;
}
.unique-feedback-area.wrap-with-black-color {
  background-image: unset;
}
.unique-feedback-area.wrap-with-black-color::before {
  display: none;
}
.unique-feedback-area.with-linear-gradient-color {
  background-image: unset;
  background: linear-gradient(86.42deg, #2e66b9 0%, #8859ec 101.05%);
}
.unique-feedback-area.with-linear-gradient-color::before {
  background-color: var(--whiteColor);
}

.unique-single-feedback {
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 40px 45px;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
  transition: var(--transition);
  position: relative;
  z-index: 1;
}
.unique-single-feedback .rating {
  padding: 0;
  margin-bottom: 15px;
}
.unique-single-feedback .rating li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}
.unique-single-feedback .rating li:last-child {
  margin-right: 0;
}
.unique-single-feedback .rating li i {
  font-size: 18px;
  color: #ffb820;
}
.unique-single-feedback .client-info {
  margin-top: 30px;
  position: relative;
  padding-left: 70px;
}
.unique-single-feedback .client-info img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.unique-single-feedback .client-info h3 {
  margin-bottom: 0;
  color: var(--mainColor);
  font-size: 20px;
}
.unique-single-feedback .client-info span {
  display: block;
  color: var(--paragraphColor);
  margin-top: 10px;
  font-size: 15px;
}
.unique-single-feedback .quote {
  position: absolute;
  right: 45px;
  top: 55px;
  z-index: -1;
}
.unique-single-feedback:hover {
  border: 1px solid var(--mainColor);
}
.unique-single-feedback.with-black-color {
  background-color: #161618;
  border: 1px solid #161618;
}
.unique-single-feedback.with-black-color p {
  color: #f4f5f8;
}
.unique-single-feedback.with-black-color .client-info h3 {
  color: #f4f5f8;
}
.unique-single-feedback.with-black-color .client-info span {
  color: #f4f5f8;
}
.unique-single-feedback.with-black-color .quote {
  top: unset;
  bottom: 35px;
}
.unique-single-feedback.with-black-color .quote i {
  color: var(--mainColor);
  font-size: 45px;
}
.unique-single-feedback.with-black-color:hover {
  border: 1px solid var(--mainColor);
}

.unique-feedback-slides.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}
.unique-feedback-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background: #e1e1fa;
  display: block;
  transition: var(--transition);
  border-radius: 10px;
  display: inline-block;
}
.unique-feedback-slides.owl-theme .owl-dots .owl-dot:hover span, .unique-feedback-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
  width: 30px;
}

.unique-feedback-line-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/*================================================
Mobile App Area CSS
=================================================*/
.mobile-app-area {
  background-color: #f4fafd;
}

.mobile-app-content {
  padding-left: 90px;
}
.mobile-app-content h3 {
  font-size: 40px;
  margin-bottom: 12px;
  line-height: 1.4;
}
.mobile-app-content h3 span {
  background: linear-gradient(89.21deg, #2f66b9 21.64%, #8659eb 71.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.mobile-app-content p {
  margin-bottom: 0;
}
.mobile-app-content .app-btn-box {
  margin-top: 25px;
}
.mobile-app-content .app-btn-box .playstore-btn {
  position: relative;
  border-radius: 5px;
  margin-right: 15px;
  display: inline-block;
  color: var(--paragraphColor);
  background-color: var(--whiteColor);
  box-shadow: 0 7px 18px 0 rgba(107, 124, 147, 0.15);
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  padding-left: 70px;
  padding-right: 25px;
  padding-bottom: 12px;
}
.mobile-app-content .app-btn-box .playstore-btn span {
  display: block;
  margin-top: 5px;
  color: var(--blackColor);
  transition: var(--transition);
  font-size: 17.5px;
  font-weight: 700;
}
.mobile-app-content .app-btn-box .playstore-btn img {
  transform: translateY(-50%);
  position: absolute;
  left: 20px;
  top: 50%;
}
.mobile-app-content .app-btn-box .playstore-btn:hover {
  background-color: var(--mainColor);
  transform: translateY(-3px);
  color: var(--whiteColor);
}
.mobile-app-content .app-btn-box .playstore-btn:hover span {
  color: var(--whiteColor);
}
.mobile-app-content .app-btn-box .applestore-btn {
  position: relative;
  border-radius: 5px;
  display: inline-block;
  color: var(--paragraphColor);
  background-color: var(--whiteColor);
  box-shadow: 0 7px 18px 0 rgba(107, 124, 147, 0.15);
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  padding-left: 68px;
  padding-right: 25px;
  padding-bottom: 12px;
}
.mobile-app-content .app-btn-box .applestore-btn span {
  display: block;
  margin-top: 5px;
  color: var(--blackColor);
  transition: var(--transition);
  font-size: 17.5px;
  font-weight: 700;
}
.mobile-app-content .app-btn-box .applestore-btn img {
  transform: translateY(-50%);
  position: absolute;
  left: 20px;
  top: 50%;
}
.mobile-app-content .app-btn-box .applestore-btn:hover {
  background-color: var(--blackColor);
  transform: translateY(-3px);
  color: var(--whiteColor);
}
.mobile-app-content .app-btn-box .applestore-btn:hover span {
  color: var(--whiteColor);
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-card {
  background: #f4fafd;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #f4fafd;
  transition: var(--transition);
  margin-bottom: 30px;
}
.single-blog-card .tag {
  position: relative;
  margin-top: -40px;
}
.single-blog-card .tag span {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  font-size: 14px;
  font-weight: 500;
}
.single-blog-card .post-meta {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 18px;
}
.single-blog-card .post-meta li {
  display: inline-block;
  list-style-type: none;
  font-size: 15px;
  color: var(--paragraphColor);
  position: relative;
  margin-right: 20px;
  padding-left: 22px;
}
.single-blog-card .post-meta li:last-child {
  margin-right: 0;
}
.single-blog-card .post-meta li i {
  color: var(--mainColor);
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 15px;
}
.single-blog-card h3 {
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 12px;
}
.single-blog-card h3 a {
  color: var(--blackColor);
}
.single-blog-card .info {
  margin-top: 20px;
  position: relative;
  padding-left: 70px;
}
.single-blog-card .info img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-blog-card .info h3 {
  margin-bottom: 0;
  font-size: 18px;
}
.single-blog-card .info span {
  display: block;
  color: var(--paragraphColor);
  margin-top: 10px;
  font-size: 15px;
}
.single-blog-card:hover {
  border: 1px solid var(--mainColor);
  transform: translateY(-5px);
}
.single-blog-card:hover h3 a {
  color: var(--mainColor);
}
.single-blog-card.with-black-color {
  border: 1px solid rgba(203, 220, 221, 0.06);
  background-color: transparent;
}
.single-blog-card.with-black-color .post-meta li {
  color: #f4f5f8;
}
.single-blog-card.with-black-color .post-meta li i {
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.single-blog-card.with-black-color h3 a {
  color: #f4f5f8;
}
.single-blog-card.with-black-color p {
  color: #f4f5f8;
}
.single-blog-card.with-black-color .info h3 {
  color: #f4f5f8;
}
.single-blog-card.with-black-color .info span {
  color: #f4f5f8;
}
.single-blog-card.with-black-color:hover {
  border: 1px solid #161618;
  background-color: #161618;
  transform: translateY(-5px);
}

/*================================================
Subscribe Wrap Area CSS
=================================================*/
.subscribe-wrap-area {
  position: relative;
  z-index: 1;
}
.subscribe-wrap-area::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 150px;
  width: 100%;
  background-color: var(--blackColor);
  z-index: -1;
}
.subscribe-wrap-area.with-black-color {
  background-color: #1f1f22;
}
.subscribe-wrap-area.with-black-color::before {
  background-color: var(--blackColor);
}

.subscribe-wrap-inner-box {
  background: linear-gradient(86.42deg, #2e66b9 0%, #8859ec 101.05%);
  max-width: 950px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 150px;
  padding-right: 150px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.subscribe-wrap-inner-box .subscribe-content {
  text-align: center;
}
.subscribe-wrap-inner-box .subscribe-content h2 {
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 40px;
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form {
  max-width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  height: 65px;
  padding-left: 25px;
  border-radius: 70px;
  padding-top: 5px;
  outline: 0;
  color: var(--blackColor);
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form .input-newsletter::placeholder {
  color: var(--paragraphColor);
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form button {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: none;
  height: 55px;
  padding: 0 30px;
  border-radius: 70px;
  transition: var(--transition);
  line-height: 60px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form button:hover {
  background-color: var(--optionalColor);
  color: var(--whiteColor);
}
.subscribe-wrap-inner-box .subscribe-content .newsletter-form #validator-newsletter {
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  color: var(--whiteColor);
  bottom: -35px;
}
.subscribe-wrap-inner-box .subscribe-shape-1 {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}
.subscribe-wrap-inner-box .subscribe-shape-2 {
  position: absolute;
  left: 150px;
  top: 50px;
  z-index: -1;
  animation: moveLeftBounce 3s linear infinite;
}
.subscribe-wrap-inner-box .subscribe-shape-3 {
  position: absolute;
  right: 150px;
  top: 50px;
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

/*================================================
Cyber Security Banner Area CSS
=================================================*/
.cs-banner-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #161618;
  padding-top: 180px;
  padding-bottom: 150px;
}
.cs-banner-section::before {
  position: absolute;
  content: "";
  width: 348px;
  height: 100%;
  background-color: #1f1f22;
  left: 0;
  top: 0;
  z-index: -1;
}
.cs-banner-section .container-fluid {
  padding-left: 80px;
  padding-right: 50px;
}

.cs-banner-content .sub-title {
  color: var(--whiteColor);
  font-size: var(--fontSize);
  font-weight: 500;
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  z-index: 1;
}
.cs-banner-content .sub-title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 5px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.cs-banner-content h1 {
  font-size: 90px;
  font-weight: 800;
  margin-bottom: 15px;
  color: var(--whiteColor);
}
.cs-banner-content p {
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 22px;
  font-weight: 500;
}
.cs-banner-content .banner-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.cs-banner-content .banner-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.cs-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.cs-banner-content .banner-btn li .video-btn {
  margin-left: 5px;
  font-size: 15.5px;
  font-weight: 600;
  color: var(--whiteColor);
}
.cs-banner-content .banner-btn li .video-btn i {
  background: rgba(47, 143, 232, 0.07);
  height: 45px;
  width: 45px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.cs-banner-content .banner-btn li .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.cs-banner-shape {
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: -1;
}
.cs-banner-shape img {
  animation-name: rotateMe;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cs-lock {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateY(-10%) translateX(-50%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

/*================================================
Card Area CSS
=================================================*/
.cs-card-area {
  padding-top: 70px;
  padding-bottom: 40px;
}

.cs-card-content {
  margin-bottom: 30px;
}
.cs-card-content h3 {
  font-size: 20px;
  margin-bottom: 0;
  position: relative;
  padding-top: 22px;
  font-weight: 600;
}
.cs-card-content h3 a {
  color: var(--whiteColor);
}
.cs-card-content h3::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 1px;
  left: 0;
  top: 0;
  background: rgba(47, 143, 232, 0.1);
}
.cs-card-content h3::after {
  position: absolute;
  content: "";
  width: 115px;
  height: 1px;
  left: 0;
  top: 0;
  background: rgba(47, 143, 232, 0.4);
}

/*================================================
Features Area CSS
=================================================*/
.cs-features-area {
  overflow: hidden;
}
.cs-features-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.cs-features-item {
  position: relative;
  z-index: 1;
  padding: 80px 70px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: var(--transition);
}
.cs-features-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(31, 31, 34, 0.96) 0%, rgba(31, 31, 34, 0.9) 58.33%, rgba(22, 22, 24, 0.6) 100%);
  transition: var(--transition);
  z-index: -1;
}
.cs-features-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(80.77deg, #7906d3 0%, #1377d2 100%);
  transition: var(--transition);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.cs-features-item .content h3 {
  font-size: 24px;
  margin-bottom: 16.8px;
}
.cs-features-item .content h3 a {
  color: var(--whiteColor);
}
.cs-features-item .content p {
  color: var(--whiteColor);
  margin-bottom: 20px;
}
.cs-features-item .content .get-started-btn {
  font-size: 15px;
  font-weight: bold;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  padding-left: 10px;
}
.cs-features-item .content .get-started-btn::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-color: var(--mainColor);
  border-radius: 50px;
  left: 0;
  bottom: -2.5px;
  z-index: -1;
}
.cs-features-item .bg-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
.cs-features-item .bg-icon i {
  font-size: 120px;
  color: var(--whiteColor);
  opacity: 5%;
}
.cs-features-item:hover::before {
  opacity: 0;
  visibility: hidden;
}
.cs-features-item:hover::after {
  opacity: 1;
  visibility: visible;
}

/*================================================
CS Partner Area CSS
=================================================*/
.cs-partner-area .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.cs-partner-item {
  text-align: center;
  margin-bottom: 30px;
}
.cs-partner-item a img {
  display: inline-block !important;
  max-width: 100%;
  width: auto !important;
  margin: auto;
}

/*================================================
CS About Area CSS
=================================================*/
.cs-about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cs-about-area::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 955px;
  background-color: #161618;
  left: 0;
  top: 0;
  z-index: -1;
}

.cs-about-content {
  padding-left: 35px;
}
.cs-about-content .sub-title {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 18px;
}
.cs-about-content h3 {
  font-size: 40px;
  color: var(--whiteColor);
  margin-bottom: 15px;
}
.cs-about-content h3 span {
  position: relative;
  z-index: 1;
}
.cs-about-content h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.cs-about-content p {
  color: #f4f5f8;
  margin-bottom: 0;
}
.cs-about-content .cs-about-card {
  margin-top: 30px;
}
.cs-about-content .cs-about-card .icon {
  display: inline-block;
  height: 85px;
  width: 85px;
  line-height: 85px;
  background: #161618;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  color: var(--whiteColor);
  font-size: 35px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.cs-about-content .cs-about-card .icon::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 85px;
  width: 85px;
  line-height: 85px;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(80.77deg, #7906d3 0%, #1377d2 100%);
  z-index: -1;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.cs-about-content .cs-about-card h4 {
  color: var(--whiteColor);
  font-size: 20px;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.cs-about-content .cs-about-card h4::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: rgba(47, 143, 232, 0.1);
}
.cs-about-content .cs-about-card h4::after {
  position: absolute;
  content: "";
  width: 115px;
  height: 1px;
  left: 0;
  bottom: 0;
  background: rgba(47, 143, 232, 0.4);
}
.cs-about-content .cs-about-card:hover .icon::before {
  opacity: 1;
  visibility: visible;
}
.cs-about-content .about-btn {
  margin-top: 30px;
}

/*================================================
CS Choose Area CSS
=================================================*/
.cs-choose-content {
  padding-right: 25px;
}
.cs-choose-content .sub-title {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.cs-choose-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
  color: var(--whiteColor);
}
.cs-choose-content h3 span {
  position: relative;
  z-index: 1;
}
.cs-choose-content h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.cs-choose-content p {
  margin-bottom: 0;
  color: #f4f5f8;
}
.cs-choose-content .list {
  padding: 0;
  margin-top: 22px;
  margin-bottom: 0;
}
.cs-choose-content .list li {
  list-style-type: none;
  font-size: 16.5px;
  font-weight: bold;
  color: #f4f5f8;
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.cs-choose-content .list li:last-child {
  margin-bottom: 0;
}
.cs-choose-content .list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #76baaf;
  font-size: 16.5px;
}
.cs-choose-content .choose-btn {
  margin-top: 28px;
}

/*================================================
CS Services Area CSS
=================================================*/
.cs-services-card {
  margin-bottom: 30px;
  background: #161618;
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cs-services-card::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(80.77deg, #7906d3 0%, #1377d2 100%);
  z-index: -1;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.cs-services-card .image {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background: #1f1f22;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  text-align: center;
  border-radius: 50%;
  margin-bottom: 28px;
  transition: var(--transition);
}
.cs-services-card .image img {
  max-width: 65px;
}
.cs-services-card h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.cs-services-card h3 a {
  color: var(--whiteColor);
}
.cs-services-card p {
  color: #f4f5f8;
}
.cs-services-card:hover {
  transform: translateY(-5px);
}
.cs-services-card:hover::before {
  opacity: 1;
  visibility: visible;
}

/*================================================
CS Fun Facts Area CSS
=================================================*/
.cs-fun-facts-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cs-fun-facts-area::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1150px;
  background-color: #161618;
  right: 0;
  top: 0;
  z-index: -1;
}

.cs-single-fun-facts {
  margin-bottom: 30px;
  padding-bottom: 35px;
  position: relative;
}
.cs-single-fun-facts::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 3.2px;
  left: 0;
  bottom: 0;
  background: var(--mainColor);
}
.cs-single-fun-facts .number {
  font-size: 14px;
  color: var(--paragraphColor);
  font-weight: bold;
  display: inline-block;
  margin-bottom: 22px;
}
.cs-single-fun-facts h3 {
  position: relative;
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 38px;
}
.cs-single-fun-facts h3 .sign-icon {
  display: inline-block;
  font-size: 35px;
  margin-left: 2px;
}
.cs-single-fun-facts p {
  line-height: initial;
  margin-top: 12px;
  color: #f4f5f8;
  font-size: 16.8px;
  font-weight: 500;
}

/*================================================
Leading Technology Area CSS
=================================================*/
.leading-technology-content {
  padding-left: 30px;
}
.leading-technology-content .sub-title {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.leading-technology-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
  color: var(--whiteColor);
}
.leading-technology-content h3 span {
  position: relative;
  z-index: 1;
}
.leading-technology-content h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.leading-technology-content p {
  margin-bottom: 0;
  color: #f4f5f8;
}
.leading-technology-content .list {
  padding: 0;
  margin-top: 22px;
  margin-bottom: 0;
}
.leading-technology-content .list li {
  border-radius: 5px;
  list-style-type: none;
  font-size: 15.5px;
  font-weight: 600;
  color: #f4f5f8;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid rgba(203, 220, 221, 0.06);
  padding: 10px 10px 10px 38px;
  transition: var(--transition);
}
.leading-technology-content .list li:last-child {
  margin-bottom: 0;
}
.leading-technology-content .list li::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background: linear-gradient(80.77deg, #7906d3 0%, #1377d2 100%);
  border-radius: 50px;
  left: 15px;
  top: 14.5px;
}
.leading-technology-content .list li:hover {
  border: 1px solid var(--optionalColor);
  transform: translateY(-2px);
}

/*================================================
CS Overview Area CSS
=================================================*/
.cs-overview-item {
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid rgba(203, 220, 221, 0.06);
  border-radius: 5px;
  transition: var(--transition);
}
.cs-overview-item .cs-overview-content h3 {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--whiteColor);
  line-height: 1.4;
}
.cs-overview-item .cs-overview-content .get-started-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  padding-left: 10px;
}
.cs-overview-item .cs-overview-content .get-started-btn::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  background-color: var(--mainColor);
  border-radius: 50px;
  left: 0;
  bottom: 0.5px;
  z-index: -1;
}
.cs-overview-item:hover {
  border: 1px solid var(--mainColor);
  transform: translateY(-5px);
}

/*================================================
Big Data Banner Area CSS
=================================================*/
.bd-banner-section {
  background-color: #f1f9f9;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
}
.bd-banner-section .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.bd-banner-content {
  max-width: 650px;
}
.bd-banner-content .sub-title {
  background-color: var(--whiteColor);
  box-shadow: -2px 6px 15px rgba(67, 127, 236, 0.07);
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  font-weight: 500;
  display: inline-block;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 8px 15px;
}
.bd-banner-content h1 {
  font-size: 55px;
  font-weight: 800;
  margin-bottom: 15px;
}
.bd-banner-content h1 span {
  position: relative;
  z-index: 1;
}
.bd-banner-content h1 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.bd-banner-content p {
  margin-bottom: 0;
}
.bd-banner-content .banner-btn {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.bd-banner-content .banner-btn li {
  display: inline-flex;
  margin-right: 20px;
}
.bd-banner-content .banner-btn li:last-child {
  margin-right: 0;
}
.bd-banner-content .banner-btn li .video-btn {
  margin-left: 5px;
  font-size: 15.5px;
  font-weight: 600;
  color: var(--blackColor);
}
.bd-banner-content .banner-btn li .video-btn i {
  background: rgba(47, 143, 232, 0.07);
  height: 45px;
  width: 45px;
  display: inline-block;
  color: var(--mainColor);
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  padding-left: 5px;
  margin-right: 8px;
  transition: var(--transition);
}
.bd-banner-content .banner-btn li .video-btn:hover i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.bd-banner-image {
  position: relative;
  z-index: 1;
  text-align: center;
}
.bd-banner-image .circle-shape {
  width: 865px;
  height: 650px;
  border-radius: 50%;
  background-color: var(--whiteColor);
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -150px;
}

.bd-banner-shape-1 {
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

.bd-banner-shape-2 {
  position: absolute;
  top: 5%;
  left: 45%;
  transform: translateY(-5%) translateX(-45%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

.bd-banner-shape-3 {
  position: absolute;
  bottom: 10%;
  left: 30%;
  transform: translateY(-10%) translateX(-30%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

/*================================================
BD Fun Facts Area CSS
=================================================*/
.bd-fun-facts-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bd-fun-facts-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.bd-fun-facts-area::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background: #f1f9f9;
  z-index: -1;
}

.bd-fun-facts-inner-box {
  background: linear-gradient(80.77deg, #7906d3 0%, #1377d2 100%);
  border-radius: 0px 20px 20px 0px;
  max-width: 1320px;
  padding-left: 50px;
  padding-right: 50px;
}

.bd-single-fun-facts {
  margin-bottom: 30px;
  padding-bottom: 28px;
  position: relative;
  padding-left: 100px;
}
.bd-single-fun-facts::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 3.2px;
  bottom: 0;
  background: #fbbde7;
}
.bd-single-fun-facts .image-icon {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
  text-align: center;
  border-radius: 50%;
  margin-bottom: 28px;
  transition: var(--transition);
  position: absolute;
  left: 0;
  top: 0;
}
.bd-single-fun-facts .image-icon img {
  max-width: 45px;
}
.bd-single-fun-facts h3 {
  position: relative;
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 38px;
}
.bd-single-fun-facts h3 .sign-icon {
  display: inline-block;
  font-size: 35px;
  margin-left: 2px;
}
.bd-single-fun-facts p {
  line-height: initial;
  margin-top: 10px;
  color: #f4f5f8;
  font-size: 16.8px;
  font-weight: 500;
}
.bd-single-fun-facts span.odometer-formatting-mark {
  display: none;
}

/*================================================
BD Featured Area CSS
=================================================*/
.bd-featured-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bd-featured-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.bd-featured-card .featured-image {
  display: inline-block;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
  margin-bottom: 28px;
  transition: var(--transition);
}
.bd-featured-card .featured-image img {
  max-width: 45px;
}
.bd-featured-card h3 {
  font-size: 22px;
  margin-bottom: 14px;
}
.bd-featured-card:hover {
  transform: translateY(-5px);
}

.bd-featured-shape {
  position: absolute;
  top: 5%;
  right: 5%;
  transform: translateY(-5%) translateX(-5%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

/*================================================
BD About Area CSS
=================================================*/
.bd-about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bd-about-content {
  padding-left: 35px;
}
.bd-about-content .sub-title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 18px;
}
.bd-about-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
}
.bd-about-content h3 span {
  position: relative;
  z-index: 1;
}
.bd-about-content h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.bd-about-content p {
  margin-bottom: 0;
}
.bd-about-content .inner-content-card {
  border-radius: 5px;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.07);
  margin-top: 30px;
  position: relative;
  padding: 30px 30px 30px 138px;
  transition: var(--transition);
}
.bd-about-content .inner-content-card .icon-image {
  display: inline-block;
  height: 85px;
  width: 85px;
  line-height: 85px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
  transition: var(--transition);
  position: absolute;
  left: 30px;
  top: 30px;
}
.bd-about-content .inner-content-card h4 {
  font-size: 22px;
  margin-bottom: 12.8px;
}
.bd-about-content .inner-content-card:hover {
  transform: translateY(-5px);
}

.bd-about-shape {
  position: absolute;
  top: 5%;
  right: 5%;
  transform: translateY(-5%) translateX(-5%);
  z-index: -1;
  animation: moveBounce 5s linear infinite;
}

/*================================================
BD Services Area CSS
=================================================*/
.bd-services-area {
  background-color: #f4fafd;
}

.bd-services-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.bd-services-card .services-image {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
  margin-bottom: 28px;
  transition: var(--transition);
}
.bd-services-card .services-image img {
  max-width: 45px;
}
.bd-services-card h3 {
  font-size: 24px;
  margin-bottom: 14px;
}
.bd-services-card p {
  margin-bottom: 20px;
}
.bd-services-card .get-started-btn {
  font-size: 15px;
  font-weight: bold;
  color: var(--paragraphColor);
  position: relative;
  z-index: 1;
  padding-left: 10px;
}
.bd-services-card .get-started-btn::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  background-color: #fbb8a9;
  border-radius: 50px;
  left: 0;
  bottom: 0.5px;
  z-index: -1;
  transition: var(--transition);
}
.bd-services-card:hover {
  transform: translateY(-5px);
}
.bd-services-card:hover .get-started-btn {
  color: var(--mainColor);
}
.bd-services-card:hover .get-started-btn::before {
  background-color: var(--optionalColor);
}

/*================================================
BD Choose Area CSS
=================================================*/
.bd-choose-content {
  padding-right: 25px;
}
.bd-choose-content .sub-title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 15px;
}
.bd-choose-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
}
.bd-choose-content h3 span {
  position: relative;
  z-index: 1;
}
.bd-choose-content h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 12px;
  background: linear-gradient(90deg, #d3064e -0.61%, #fe5b67 100%);
  height: 5px;
  width: 100%;
  z-index: -1;
}
.bd-choose-content p {
  margin-bottom: 0;
}
.bd-choose-content .list {
  padding: 0;
  margin-top: 22px;
  margin-bottom: 0;
}
.bd-choose-content .list li {
  list-style-type: none;
  font-size: 16.5px;
  font-weight: bold;
  color: var(--paragraphColor);
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
}
.bd-choose-content .list li:last-child {
  margin-bottom: 0;
}
.bd-choose-content .list li i {
  position: absolute;
  left: 0;
  top: 2px;
  color: #76baaf;
  font-size: 16.5px;
}
.bd-choose-content .choose-btn {
  margin-top: 28px;
}

/*================================================
BD Choose Area CSS
=================================================*/
.bd-overview-area {
  background-image: url(assets/img/big-data-home/overview/overview-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bd-overview-content {
  max-width: 650px;
  margin-left: auto;
}

.bd-overview-card {
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
  padding: 30px;
  border-radius: 5px;
  transition: var(--transition);
}
.bd-overview-card .overview-image {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  background-color: var(--whiteColor);
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
  margin-bottom: 28px;
  transition: var(--transition);
}
.bd-overview-card .overview-image img {
  max-width: 45px;
}
.bd-overview-card h3 {
  font-size: 24px;
  margin-bottom: 14px;
}
.bd-overview-card p {
  margin-bottom: 20px;
}
.bd-overview-card:hover {
  transform: translateY(-5px);
}

/*================================================
Footer Wrap Area CSS
=================================================*/
.footer-wrap-area {
  background-color: #1f102b;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-wrap-area.with-black-color {
  background-color: #161618;
}

.single-footer-card {
  margin-bottom: 30px;
}
.single-footer-card .logo {
  display: inline-block;
  margin-bottom: 25px;
}
.single-footer-card p {
  color: var(--whiteColor);
}
.single-footer-card h3 {
  margin-bottom: 25px;
  font-size: 22px;
  color: var(--whiteColor);
}
.single-footer-card .social-links {
  padding: 0;
  list-style-type: none;
  margin-top: 18px;
  margin-bottom: 0;
}
.single-footer-card .social-links li {
  display: inline-block;
  margin-right: 10px;
}
.single-footer-card .social-links li:last-child {
  margin-right: 0;
}
.single-footer-card .social-links li a i {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: var(--whiteColor);
  color: var(--paragraphColor);
  border-radius: 50%;
  font-size: var(--fontSize);
  text-align: center;
  transition: var(--transition);
}
.single-footer-card .social-links li a i:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transform: translateY(-5px);
}
.single-footer-card .footer-quick-links {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-card .footer-quick-links li {
  list-style-type: none;
  margin-bottom: 15.5px;
}
.single-footer-card .footer-quick-links li:last-child {
  margin-bottom: 0;
}
.single-footer-card .footer-quick-links li a {
  color: var(--whiteColor);
  position: relative;
  padding-left: 22px;
  font-size: var(--fontSize);
  font-weight: 400;
}
.single-footer-card .footer-quick-links li a i {
  color: var(--optionalColor);
  font-size: 15.5px;
  position: absolute;
  left: 0;
  top: 3.5px;
}
.single-footer-card .footer-quick-links li a:hover {
  color: var(--optionalColor);
}
.single-footer-card .contact-links {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-card .contact-links li {
  color: var(--whiteColor);
  list-style-type: none;
  margin-bottom: 18.5px;
  font-weight: 400;
}
.single-footer-card .contact-links li:last-child {
  margin-bottom: 0;
}
.single-footer-card .contact-links li span {
  font-weight: bold;
}
.single-footer-card .contact-links li a {
  color: var(--whiteColor);
}
.single-footer-card .contact-links li a:hover {
  color: var(--optionalColor);
}

.footer-wrap-line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.footer-wrap-shape {
  position: absolute;
  top: 10%;
  right: 5%;
  transform: translateY(-10%) translateX(-5%);
  z-index: -1;
}
.footer-wrap-shape img {
  animation-name: rotateMe;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.footer-wrap-shape-2 {
  position: absolute;
  bottom: 10%;
  left: 2%;
  transform: translateY(-10%) translateX(-2%);
  animation: moveBounce 5s linear infinite;
  z-index: -1;
}

/*================================================
Copyright Wrap Area CSS
=================================================*/
.copyright-wrap-area {
  background-color: #11011e;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-wrap-area p {
  color: var(--whiteColor);
  margin-bottom: 0;
}
.copyright-wrap-area p a {
  display: inline-block;
  color: var(--whiteColor);
}
.copyright-wrap-area p a:hover {
  color: var(--optionalColor);
}
.copyright-wrap-area .list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  text-align: right;
}
.copyright-wrap-area .list li {
  display: inline-block;
  margin: 0 10px;
  color: var(--whiteColor);
  position: relative;
}
.copyright-wrap-area .list li a {
  display: inline-block;
  color: var(--whiteColor);
}
.copyright-wrap-area .list li a:hover {
  color: var(--optionalColor);
}
.copyright-wrap-area .list li::before {
  content: "";
  position: absolute;
  right: -12px;
  top: 4px;
  width: 1px;
  height: 14px;
  background-color: var(--whiteColor);
}
.copyright-wrap-area .list li:first-child {
  margin-left: 0;
}
.copyright-wrap-area .list li:last-child {
  margin-right: 0;
}
.copyright-wrap-area .list li:last-child::before {
  display: none;
}
.copyright-wrap-area.with-black-color {
  background-color: #09090a;
}